@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap');

:root {
    --black: #141414;
    --white: #ffffff;
    --primary: #3a2d7d;
    --drkblue: #1b1342;
    --lhtblue: #f4fafa;
    --red: #cd1f28;
    --secondary: #cd1f28;
    --gray: #808080;
    --green: #63c521;
    --yellow: #ffb219;
}

body,html{height:100%;margin:0;scroll-behavior:smooth}
body{font-family: 'Figtree', sans-serif;color:var(--black);padding-top:110px;line-height:1.4}
img{max-width:100%;height:auto}
b,strong{font-weight:700}
a,button,input[type=submit]{text-decoration:none;-webkit-transition:all ease .3s;-moz-transition:all ease .3s;-o-transition:all ease .3s;-ms-transition:all ease .3s;transition:all ease .3s;position:relative;display:inline-block}
.btn-link{color:var(--red)}
.link-style{color:var(--white)}
a:focus,a:hover{color:var(--primary)}
ol,ul{margin-bottom:2rem}
li{margin-bottom:.5rem}
.figure{margin:0}
.figure-caption{color:var(--black)}
.btn,a:focus,a:hover,button{text-decoration:none;outline:0!important;outline-offset:0px!important}
.row-gap{margin:0 -5px}
.row-gap .col,.row-gap [class*=col-]{padding:0 5px}
label{font-weight:400}
.data-table{display:table;table-layout:fixed;width:100%;float:none}
.data-cell{display:table-cell;vertical-align:middle;min-width:1%;float:none}
.data-table.vtop .data-cell{vertical-align:top}
iframe{width:100%!important}
sub,sup{font-size:50%;top:-.6em}
.panel{box-shadow:none;border:none;border-radius:0}
.form-control{background-color:var(--white);box-shadow:none;border-color:var(--white);color:var(--black);border-radius:5px;height:48px;padding:.375rem .5rem;font-size:14px}
textarea.form-control{height:85px}
.form-control[disabled],.form-control[readonly],fieldset[disabled] .form-control{background-color:var(--white);opacity:1}
.attachment img,img.size-auto,img.size-full,img.size-large,img.size-medium{max-width:100%;height:auto}
.alignleft,img.alignleft{display:inline;float:left;margin-right:3%;text-align:left}
.alignright,img.alignright{display:inline;float:right;margin-left:3%;text-align:right}
.aligncenter,img.aligncenter{clear:both;display:block;margin-left:auto;margin-right:auto;text-align:center}
img.aligncenter,img.alignleft,img.alignright{margin-bottom:25px}
select,select.form-control{-webkit-appearance:none;-moz-appearance:none;appearance:none;border:1px solid #6d6d6d;background-image:url(../img/select-icon.png);background-size:10px;background-repeat:no-repeat;background-position:calc(100% - 10px) center;display:inline-block;vertical-align:middle;border-radius:0;cursor:pointer;padding-right:30px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
option:focus,select:focus{outline:0!important}
select[multiple].form-control{-webkit-appearance:none;-moz-appearance:none;appearance:none;background:0 0;padding-right:25px;height:auto}
select::-ms-expand{display:none}
.text-grey{color:var(--grey)}
img[align=left]{margin:0 30px 20px 0}
img[align=right]{margin:0 0 30px 20px}
.small,small{font-size:75%}
.big, big{font-size:120%;}
.bg-primary{background-color:var(--primary)!important}
.bg-primary-light{background-color:var(--lhtblue)!important}
.bg-secondary{background-color:var(--secondary)!important}
.text-black{color:var(--black)!important}
.text-primary{color:var(--primary)!important}
.text-secondary{color:var(--secondary)!important}
.rounded{border-radius:10px!important}
.regular{font-weight:400!important}
.medium{font-weight:600!important}
.bold{font-weight:700!important}
.bolder{font-weight:900!important}
.section{padding:60px 0}
.h1,.h2,.h3,.h4,.h5,.h6,.title-lg,.title-md,.title-sm,.title-xs,h1,h2,h3,h4,h5,h6{margin:0 0 1rem 0;line-height:normal;font-weight:700}
.title-lg,h1{font-size:40px}
.title-md,h2{font-size:28px}
.title-sm,h3{font-size:22px}
.title-xs,h4{font-size:18px}
.title-xxs,h5{font-size:16px}
.relative{position:relative}
hr{border-color:#c9c8c8;margin:3rem 0}
@-webkit-keyframes autofill{
to{background:var(--white)}
}
input:-webkit-autofill{-webkit-animation-name:autofill;-webkit-animation-fill-mode:both}
.third_section_title{font-size:1.4rem;font-weight:700}
.btn{border-radius:8px;padding:0 1.2rem;line-height:48px;font-weight:700;transition-duration:.4s;box-shadow:none!important}
.btn-group-lg>.btn,.btn-lg{padding:.5rem 1rem;font-size:20px;line-height:1.5}
.rad30{border-radius:30px}
.btn:focus{box-shadow:none}
.btn-outline,.btn-outline-outline:active,.btn-outline-outline:focus,.btn-outline-outline:hover,.btn-outline:active,.btn-outline:focus,.btn-outline:hover,.btn-outline:not(:disabled):not(.disabled).active,.btn-outline:not(:disabled):not(.disabled):active,.show>.btn-outline.dropdown-toggle{color:var(--primary)!important;border-color:var(--primary);background-color:transparent;border-width:2px}
.btn-primary{color:var(--white)!important;background-image:linear-gradient(to right bottom,#5b59aa,#504b9c,#453d8f,#3b3081,#302274)!important;border-color:var(--primary)!important}
.btn-outline-primary:active,.btn-outline-primary:focus,.btn-outline-primary:hover,.btn-primary:active,.btn-primary:focus,.btn-primary:hover,.btn-primary:not(:disabled):not(.disabled).active,.btn-primary:not(:disabled):not(.disabled):active,.show>.btn-primary.dropdown-toggle{color:var(--white);background-image:linear-gradient(to right bottom,#5b59aa,#504b9c,#453d8f,#3b3081,#302274);border-color:var(--primary)}
.btn-secondary{color:var(--white);background-image:linear-gradient(to right bottom,#eb252f,#bc1728,#8e0c20,#620516,#3a0205);border-color:transparent}
.btn-outline-secondary:active,.btn-outline-secondary:focus,.btn-outline-secondary:hover,.btn-secondary:active,.btn-secondary:focus,.btn-secondary:hover,.btn-secondary:not(:disabled):not(.disabled).active,.btn-secondary:not(:disabled):not(.disabled):active,.show>.btn-secondary.dropdown-toggle{color:var(--white);background-image:linear-gradient(to right bottom,#eb252f,#bc1728,#8e0c20,#620516,#3a0205);border-color:transparent}
.btn-transparent{color:var(--primary);background-color:transparent;border:none;border-radius:0;padding:0!important}
.btn-transparent:active,.btn-transparent:focus,.btn-transparent:hover,.btn-transparent:not(:disabled):not(.disabled).active,.btn-transparent:not(:disabled):not(.disabled):active,.show>.btn-transparent.dropdown-toggle{color:var(--red)}
.btn-white{color:var(--black);background-color:var(--white);border-color:var(--white)}
.btn-outline-white:active,.btn-outline-white:focus,.btn-outline-white:hover,.btn-white:active,.btn-white:focus,.btn-white:hover,.btn-white:not(:disabled):not(.disabled).active,.btn-white:not(:disabled):not(.disabled):active,.show>.btn-white.dropdown-toggle{color:var(--white);background-color:var(--red);border-color:var(--red)}
.btn-link:hover{color:var(--primary);text-decoration:none}
.btn-checkbox,.btn-radio{display:inline-block;margin-bottom:15px;margin-right:15px}
.btn-checkbox:last-child,.btn-radio:last-child{margin-right:0}
.btn-checkbox input[type=checkbox]+label,.btn-radio input[type=radio]+label{background-color:transparent;position:relative;padding:0 0 0 35px;margin:0;cursor:pointer;display:inline-block;color:#4c4c4c;font-weight:400;line-height:27px;text-transform:uppercase}
.btn-checkbox input[type=checkbox],.btn-radio input[type=radio]{display:none}
.btn-checkbox input[type=checkbox]+label:before{border:1px solid var(--black);content:'';display:inline-block;width:25px;height:25px;left:0;border-radius:4px;position:absolute;top:2px;background:0 0}
.btn-checkbox input[type=checkbox]:checked+label:before{background:var(--black);border-color:var(--black)}
.btn-checkbox input[type=checkbox]+label:after{content:'';position:absolute;top:50%;margin-top:-4px;left:7px;width:12px;height:5px;border:solid transparent;border-width:0 0 2px 2px;line-height:0;-webkit-transition-duration:.2s;transition-duration:.2s;-ms-transform:rotate(-45deg) scale(2);-webkit-transform:rotate(-45deg) scale(2);transform:rotate(-70deg) scale(2)}
.btn-checkbox input[type=checkbox]:checked+label:after{-ms-transform:rotate(-45deg) scale(1);-webkit-transform:rotate(-45deg) scale(1);transform:rotate(-45deg) scale(1);border-color:var(--white)}
.btn-radio input[type=radio]+label:before{border:2px solid var(--red);content:'';display:inline-block;width:22px;height:22px;left:0;position:absolute;top:2px;border-radius:50%}
.btn-checkbox input[type=checkbox]+label:hover:before,.btn-radio input[type=radio]+label:hover:before{border-color:var(--red)}
.btn-radio input[type=radio]:checked+label:before{border-color:var(--red)}
.btn-radio input[type=radio]+label:after{width:10px;height:10px;display:inline-block;position:absolute;top:8px;left:6px;line-height:0;background:var(--red);content:'';border-radius:50%;-ms-transition-duration:.2s;-webkit-transition-duration:.2s;transition-duration:.2s;-ms-transform:scale(0);-webkit-transform:scale(0);transform:scale(0)}
.btn-radio input[type=radio]:checked+label:after{-ms-transform:scale(1);-webkit-transform:scale(1);transform:scale(1)}
.btn-checkbox input[type=checkbox]:disabled+label,.btn-radio input[type=radio]:disabled+label{opacity:.5}
.checkbox-inline,.radio-inline{padding:0 20px 15px 0}
.checkbox-inline+.checkbox-inline,.radio-inline+.radio-inline{margin-top:0;margin-left:0}
.icon-span-filestyle{display:none}
.group-span-filestyle{background:var(--black)}
.group-span-filestyle .btn,.group-span-filestyle .btn-default:active:focus,.group-span-filestyle .btn-default:active:hover{color:var(--white);background:0 0;border:none}
.group-span-filestyle .btn:before{display:none}
.ui-datepicker .ui-datepicker-title select{height:auto}
.input-group{z-index:0}
.modal-content input:-webkit-autofill,.modal-content input:-webkit-autofill:focus,.modal-content input:-webkit-autofill:hover,.modal-content select:-webkit-autofill,.modal-content select:-webkit-autofill:focus,.modal-content select:-webkit-autofill:hover,.modal-content textarea:-webkit-autofill,.modal-content textarea:-webkit-autofill:focus,.modal-content textarea:-webkit-autofill:hover{-webkit-text-fill-color:#fff;-webkit-box-shadow:0 0 0 1000px #3a2d7d inset;transition:background-color 5000s ease-in-out 0s}
#header{background-color:transparent;position:fixed;width:100%;top:0;left:0;z-index:6;height:110px;transition-duration:.6s;padding-right:0!important}
#header.is-fixed{box-shadow:0 2px 4px rgb(15 34 58 / 12%);background:var(--white)}
.nav-primary,.nav-secondary{position:relative;z-index:2}
#header .nav-primary{padding:.7rem 0}
.navbar{padding:0}
.navbar-brand{padding-top:0;padding-bottom:0;margin-right:3rem;width:150px}
.navbar-light .navbar-nav .nav-item{margin:0;display:flex;align-items:center}
.navbar-light .navbar-nav .nav-link{color:var(--black);margin:0 .5rem;position:relative;font-weight:700}
.navbar-light .navbar-nav .nav-link:before{transition-duration:.4s;position:absolute;content:'';bottom:0;left:0;width:0;height:2px;background-color:var(--red)}
.navbar-light .navbar-nav .search-ico{width:41px;height:41px;background-position:center center;background-image:url('../img/search-ico.png');background-repeat:no-repeat;text-indent:-999em;margin-left:1rem;margin-right:1rem;padding-left:0;padding-right:0}
.navbar-light .navbar-nav .search-ico.active{background-image:url('../img/close-ico.png');background-repeat:no-repeat}
.navbar-light .navbar-nav .nav-link.whatsapp-ico{width:41px;height:41px;background-position:center center;background-image:url('../img/whatsapp-ico.png');background-repeat:no-repeat;text-indent:-999em;margin-left:1rem;margin-right:1rem;padding-left:0;padding-right:0}
.navbar-light .navbar-nav .nav-item:hover .nav-link.whatsapp-ico,.navbar-light .navbar-nav .nav-item:hover .search-ico{background-color:transparent}
.navbar-light .navbar-nav .nav-item:hover .nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link:focus,.navbar-light .navbar-nav .nav-link:hover{color:var(--red)}
.navbar-light .navbar-nav .nav-link:hover:before{width:100%}
.dropdown-menu{min-width:250px}
.dropdown-item{padding:1rem 1.5rem;display:flex;align-items:center;line-height:normal}
.dropdown-item.active,.dropdown-item:active{color:var(--black);background-color:rgba(0,0,0,.2)}
.navbar-light .navbar-nav .nav-link.btn:before,.navbar-light .navbar-nav .nav-link.translation:before,.navbar-light .navbar-nav .nav-link.whatsapp-ico:before,.navbar-light .navbar-nav .search-ico:before{display:none}
.navbar-light .navbar-nav .nav-link.btn{font-size:16px;color:var(--white);padding:0 30px;white-space:nowrap;text-transform:none;font-weight:700}
.right-navbar .language-ico{line-height:46px;text-transform:none;padding:0 1rem;min-width:120px}
.navbar-light .right-navbar .navbar-nav .language-ico .nav-link{padding:0;margin:0;color:var(--primary);width:100%;text-align:left;font-weight:700;display:flex;align-items:center}
.navbar-light .right-navbar .navbar-nav .language-ico .nav-link:before{display:none}
.dropdown-toggle::after{vertical-align:.15em;margin-left:auto}
#header .nav-secondary .nav-link{color:var(--white);font-size:.8rem}
.figure{display:block}
.figure-img{margin-bottom:0}
.entry-title{max-width:80%;margin:auto;margin-bottom:3rem}
.entry-title .title-head{color:var(--primary);position:relative;font-weight:900;font-size:36px;margin:0 0 .5rem 0;padding:0}
.entry-title p{line-height:1.6}
.blu{color:var(--primary)}
.home-banner .swiper-container-horizontal>.swiper-pagination-bullets,.home-banner .swiper-pagination-custom,.home-banner .swiper-pagination-fraction{bottom:115px!important}
#footer{background-color:var(--drkblue);background-image:url('../img/footer-bg.png');background-repeat:no-repeat;background-position:center bottom;background-size:contain;padding:40px 0;color:var(--white);position:relative}
#footer .container{position:relative;z-index:3}
#footer ul{margin:0;padding:0;list-style:none}
#footer a{color:var(--white)}
#footer a:hover{color:var(--yellow)}
#footer .social-links ul{display:flex;align-items:center;justify-content:flex-end}
#footer .social-links li{margin:0 10px}
#footer .social-links li a{color:var(--white)}
#footer .social-links li a:hover{color:var(--yellow)}
#footer .social-links li a svg{width:24px;height:24px}
#footer .footer-title{color:var(--white);margin:.5rem 0 2rem 0}
#footer .footer-container ul li{margin:1.5rem 0}
#footer p{line-height:30px}
.dreamit-sec{background-attachment:fixed;background-color:#f7f7f9;background-image:url('../img/dream-back.jpg');background-position:right bottom;background-repeat:no-repeat}
.dreamit-sec .col-md-4:nth-child(1n) .dream-colum figure:before{right:-10px}
.dreamit-sec .col-md-4:nth-child(2n) .dream-colum figure:before{right:-10px}
.dreamit-sec .col-md-4:nth-child(2n) .dream-colum figure:after{left:-10px}
.dreamit-sec .col-md-4:nth-child(3n) .dream-colum figure:before{left:-10px}
.dream-colum figure{margin:0;position:relative}
.dream-colum figure:before,.dreamit-sec .col-md-4:nth-child(2n) .dream-colum figure:after{position:absolute;content:'';height:3px;width:30%;top:50%;transform:translateY(-50%);border-bottom:2px dashed rgba(27,19,66,.5)}
.dream-colum figure figcaption,.dream-colum p{max-width:400px;margin:auto}
.dream-colum figure figcaption{color:var(--primary);font-size:24px;font-weight:700;text-transform:uppercase;margin-top:1rem;margin-bottom:1rem}
.satisfied-customer{background-attachment:fixed;background-color:var(--primary);background-image:url('../img/customer-bg.jpg');background-position:right bottom;background-repeat:no-repeat;color:var(--white);background-size:cover}
.customer-content{width:100%}
.satisfied-customer .entry-title{max-width:550px;margin-left:0}
.satisfied-customer .entry-title .title-md{color:var(--white)}
.satisfied-pnt{display:flex;flex-wrap:wrap;justify-content:space-between;width:max-content}
.points-details{width:209px;margin:0 auto}
.blog-heading{font-size:26px!important}
.satisfied-pnt .points{padding:0 5px;text-align:center}
.satisfied-pnt .points .pnt-sec{display:flex;align-items:center;justify-content:center}
.satisfied-pnt .points figure{margin:0 5px 0 0;max-width:55px}
.satisfied-pnt .points p{overflow:hidden;margin:0;color:#fff}
.satisfied-pnt .points p strong{font-size:32px;font-weight:700;display:block;color:var(--white);padding:0}
.satisfied-pnt .points p span{display:block;color:var(--white);font-size:14px}
.ads-sec figure{overflow:hidden;margin:0}
.ads-sec figcaption{position:absolute;top:50%;left:25px;width:100%;transform:translateY(-50%);font-size:18px;color:var(--primary)}
.ads-sec figcaption strong{font-size:20px}
.ads-sec figure img{transition-duration:.4s}
.ads-sec figure:hover img{transform:scale(1.05)}
.blog-sec{overflow:hidden}
.blog-list{position:relative}
.blog-list .entry-post{background-color:var(--red);position:relative}
.blog-list .entry-post a,.blog-list .entry-post figure img{display:block;width:100%}
.blog-list .entry-post figure{position:relative;margin:0;padding-top:400px}
.blog-list .entry-post .post-cat,.blog-list .entry-post figure figcaption,.blog-list .entry-post figure img{transition-duration:.4s}
.blog-list .entry-post:hover figure img{opacity:.4}
.blog-list .entry-post figure figcaption{position:absolute;left:0;bottom:0;width:100%;padding:35px;color:var(--white);z-index:5;opacity:0;visibility:hidden}
.blog-list .entry-post:hover .post-cat,.blog-list .entry-post:hover figure figcaption{opacity:1;visibility:visible}
.blog-list .entry-post figure img{position:absolute;top:0;left:0;object-position:center center;height:100%;object-fit:cover}
.blog-list .entry-post .post-cat{position:absolute;top:85%;left:35px;z-index:5;background:rgba(235,37,47,.6);color:var(--white);padding:0 15px;line-height:40px;transition-duration:.4s}
.blog-list .entry-post:hover .post-cat{top:10%;color:var(--black);background-color:#fbf5e8}
.blog-list .entry-post figure figcaption .post-date{padding-left:20px;border-left:1px solid var(--white)}
.blog-list .entry-post figure figcaption .post-title{padding:20px 0;font-size:20px;line-height:normal;font-weight:600}
.blog-list .entry-post figure figcaption .post-link{text-decoration:underline}
.swiper-container{padding-bottom:60px!important}
.swiper-container-horizontal>.swiper-pagination-bullets,.swiper-pagination-custom,.swiper-pagination-fraction{bottom:0!important}
.swiper-pagination-bullet{width:30px!important;height:5px!important;border-radius:35px!important;background:var(--gray)!important;opacity:.5!important}
.swiper-pagination-bullet-active{opacity:1!important;background:var(--red)!important}
.customer-sec{background-color:var(--lhtblue)}
.customer-content .owl-item{padding:5px}
.customer-content .item{margin:auto;text-align:center;background-color:transparent;padding:30px;transition-duration:.4s;transform:scale(.9)}
.customer-content .item figure{width:130px;height:130px;overflow:hidden;border-radius:100%;margin:0 auto 1rem}
.customer-content .item .user-name{color:var(--black);font-size:20px;font-weight:600;margin-bottom:5px}
.customer-content .item .user-desgination{color:var(--red);margin-bottom:5px}
.customer-content .item .user-info{box-shadow:0 0 15px 0 rgba(0,0,0,.1);background-color:var(--white);color:var(--black);position:relative;border-radius:8px;padding:35px 75px;margin-bottom:50px}
.customer-content .item .user-info:before{position:absolute;content:'';width:43px;height:32px;background:url('../img/quote-blk.png') no-repeat center center;background-size:cover;left:20px;top:20px}
.customer-content .item .user-info:after{position:absolute;content:'';bottom:-15px;left:0;right:0;margin:auto;width:0;height:0;border-left:15px solid transparent;border-right:15px solid transparent;border-top:15px solid var(--white)}
.customer-content .swiper-slide.swiper-slide-active .item{transform:scale(1)}
.customer-content .swiper-slide.swiper-slide-active .item .user-info{background-color:var(--primary);color:var(--white)}
.customer-content .swiper-slide.swiper-slide-active .item .user-info:before{background:url('../img/quote.png') no-repeat center center}
.customer-content .swiper-slide.swiper-slide-active .item .user-info:after{border-top-color:var(--primary)}
.customer-content .item .user-info p{margin:0}
.customer-content .owl-item{padding-top:100px}
.customer-content .owl-item.center .item{background-color:var(--white);margin-top:-100px;box-shadow:0 0 10px 0 rgba(0,0,0,.2)}
.customer-content .owl-item.center .item .user-info{display:block}
.question-tab .nav-item .nav-link{background-color:transparent;margin-bottom:1rem;border:none;border-top-left-radius:0;border-top-right-radius:0;cursor:pointer;position:relative;font-size:18px;font-weight:700;color:var(--black);border-left:6px solid;border-left-color:transparent;padding:20px 25px;text-transform:uppercase}
.question-tab .nav-item .nav-link.active{color:var(--primary);border-left-color:var(--primary)}
.question-tab .nav-item .tab-content{flex:0 0 auto;width:75%}
.resp-tabs-list{margin:0;padding:0;list-style:none}
.resp-tabs-list li{font-size:18px;font-weight:700;color:var(--red);border-left:6px solid var(--red);padding:15px 25px;margin-bottom:15px}
.Offerings-tabs .nav-tabs,.tab-wrapper .nav-tabs{display:flex;justify-content:center;position:relative}
.Offerings-tabs .nav-tabs:before,.career-at:after,.career-at:before,.organization-building:after,.organization-building:before{content:'';position:absolute;top:20px;left:20px;background:url('../img/blog-back.png') no-repeat;width:150px;background-size:100%;height:150px}
.no-dots .nav-tabs:before{display:none}
.Offerings-tabs .nav-tabs .nav-item,.tab-wrapper .nav-tabs .nav-item{margin:0}
.Offerings-tabs .nav-tabs .nav-link,.tab-wrapper .nav-tabs .nav-link{padding:1rem .8rem;margin-bottom:0;color:var(--black);font-weight:600;font-size:1.2rem;text-transform:uppercase;border:none;border-top-left-radius:0;border-top-right-radius:0;cursor:pointer;position:relative}
.Offerings-tabs .nav-tabs .nav-link:before,.tab-wrapper .nav-tabs .nav-link:before{position:absolute;content:'';width:100%;height:3px;background-color:var(--primary);left:0;bottom:0;opacity:0}
.Offerings-tabs .nav-tabs .nav-link.active,.tab-wrapper .nav-tabs .nav-link.active{color:var(--primary)}
.Offerings-tabs .nav-tabs .nav-link.active:before,.tab-wrapper .nav-tabs .nav-link.active:before{opacity:1}
.Offerings-tabs .offerings-tab-cont{padding-left:200px;position:relative;padding-top:40px}
.Offerings-tabs .offerings-tab-cont .item{width:100%;padding:0 25px}
.Offerings-tabs .offerings-tab-cont .item a{display:block;width:100%;position:relative}
.Offerings-tabs .offerings-tab-cont .item a:before{position:absolute;top:0;left:-75%;z-index:2;display:block;content:'';width:50%;height:100%;background:-webkit-linear-gradient(left,rgba(255,255,255,0) 0,rgba(255,255,255,.3) 100%);background:linear-gradient(to right,rgba(255,255,255,0) 0,rgba(255,255,255,.3) 100%);-webkit-transform:skewX(-25deg);transform:skewX(-25deg)}
.Offerings-tabs .offerings-tab-cont .item a:hover:before{-webkit-animation:shine .75s;animation:shine .75s}
@-webkit-keyframes shine{
100%{left:125%}
}
@keyframes shine{
100%{left:125%}
}
.Offerings-tabs .offerings-tab-cont .item img{width:100%;box-shadow:0 0 15px 0 rgba(0,0,0,.2)}
.Offerings-tabs .offerings-tab-cont .owl-carousel .next-slide{left:-100px;top:inherit;margin:0;bottom:0}
.Offerings-tabs .offerings-tab-cont .owl-carousel .prev-slide{left:-200px;top:inherit;margin:0;bottom:0}
.accordion .card,.accordion .card-header{border:none}
.accordion .card{margin-bottom:1.5rem}
.accordion .card-header{background-color:var(--white);padding:0;margin:0!important}
.accordion .card-header .btn h3 {font-size:18px; margin: 0; }
.accordion .card-header .btn{border-bottom:solid 1px #ddd;border-radius:0;padding:20px 45px 20px 25px;line-height:normal;text-transform:none;font-size:18px;position:relative;display:block;text-align:left;width:100%}
.accordion .card-header .btn:focus{box-shadow:none}
.accordion .card-header .btn:after{position:absolute;content:'';width:20px;height:20px;right:15px;top:20px}
.accordion .card-header .btn.unactive:after{background-image:url('../img/plus.png')}
.accordion .card-header .btn.active:after{background-image:url('../img/minus.png');transform:none}
.accordion .card-header .btn.active{border:solid 1px #ddd}
.housing-column.qsteps{min-height:325px}
.sec-form.landing-form .entry-title{margin-bottom:1.5rem;max-width:100%}
.homeloan-form.request-callback{background-color:transparent;padding:0;max-width:1470px;margin:auto;right:20px;left:0;position:absolute;z-index:5;width:98%;top:0;display:flex;justify-content:flex-end;height:100%;align-items:center}
.homeloan-form.request-callback .sec-form{background-color:var(--primary);max-width:500px;padding:1.5rem;border-radius:0;box-shadow:0 0 15px 0 rgb(0 0 0 / 20%)}
.housing-column{background-color:var(--white);min-height:470px;box-shadow:0 0 25px 0 rgba(0,0,0,.1);padding:30px;transition-duration:.4s;width:90%;margin:auto}
.housing-column p{color:var(--black);margin:0}
.housing-column:hover{background-color:var(--lhtblue)}
.housing-column .figure-img { max-height: 96px; min-height: 96px; }
.housing-column .title-xxs{color:var(--primary);min-height:60px;margin:2rem 0 0 0}
.landingpage .navbar-collapse,.landingpage .navbar-toggler,.landingpage .right-navbar,.landingpage nav.d-flex.nav-secondary.bg-primary{display:none!important}
.landingpage .copyright-sec .col-lg-4.col-md-12,.landingpage .copyright-sec .col-lg-8.col-md-12,.landingpage .footer .col-lg-8.offset-lg-1,.landingpage .questions-sec .cta{display:none}
.landingpage .footer .col-lg-3{flex:0 0 100%;max-width:100%;text-align:center}
.landingpage .disclaimer-sec.mt-5.mb-5{margin:1rem!important}
.landingpage #header,.landingpage #header.is-fixed{position:absolute;background:0 0;box-shadow:none}
.landingpage .footer-widget{text-align:center}
.landingpage .footer-widget li:first-child,.landingpage .footer-widget li:last-child, .landingpage .chat-sec.desk-chat, .landingpage .navbar-brand, .landingpage .tollfree-sec, .landingpage .payonline-sec, .landingpage .applynow-sec, .landingpage .chat-sec.mob-chat, .landingpage .partner-sec1, .landingpage .partner-sec {display:none}
.hhfl-video-page.landingpage nav.d-flex.nav-secondary.bg-primary {display:none!important; }


.eligibility-tags{display:flex;align-items:flex-start;flex-wrap:wrap}
.tag-sec{min-width:46%;max-width:46%;background-color:var(--white);border-radius:16px;box-shadow:0 0 10px 0 rgba(0,0,0,.2);padding:30px;margin-right:2%;transition-duration:.4s}
.tag-sec:nth-child(2n){margin-top:35px;margin-left:2%;margin-right:0}
.tag-sec p{opacity:.5;transition-duration:.4s}
.tag-sec small{background:url('../img/rarrow.png') no-repeat right center;display:inline-block;color:var(--primary);text-transform:uppercase;font-weight:700;font-size:18px;padding-right:25px}
.tag-sec:hover{background-color:#ffe7b7}
.tag-sec:hover p{opacity:1}
.eligibility-sec{background-color:var(--lhtblue)}
.calculator-sec .form-group{margin-bottom:50px}
.calculator-sec label{font-weight:600;font-size:16px;display:block}
.calculator-sec label span{display:block;font-weight:400;text-transform:none;font-size:14px}
.calculator-sec .centerflex{display:flex;justify-content:center}
.calculator-sec .btn-checkbox input[type=checkbox]+label,.calculator-sec .btn-radio input[type=radio]+label{padding:0 0 0 40px;line-height:32px;font-size:18px}
.calculator-sec .btn-radio input[type=radio]+label:before{border-color:var(--gray);width:28px;height:28px}
.calculator-sec .btn-radio input[type=radio]+label:after{width:16px;height:16px;border-color:var(--red)}
.calculator-sec .btn-radio input[type=radio]:checked+label:before{border-color:var(--red)}
.rangesli{position:relative;font-weight:600}
.rangesli .lrng{float:left}
.rangesli .rrng{float:right}
.input-sec{background-color:var(--white);width:100%;display:flex;margin-left:auto;align-items:center;padding:0 5px}
.procol .input-sec:first-child{margin-right:10px}
.calculator-sec input::-webkit-inner-spin-button,.calculator-sec input::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}
.calculator-sec input[type=number]{-moz-appearance:textfield}
.rangeslider-horizontal{height:10px}
.rangeslider-horizontal,.rangeslider-horizontal .rangeslider__fill{border-radius:0!important;box-shadow:none!important}
.rangeslider .rangeslider__handle{background:var(--red);border:1px solid var(--red);height:30px;width:30px}
.rangeslider-horizontal .rangeslider__handle:after{display:none}
.rangeslider-horizontal .rangeslider__fill{background-color:var(--red)!important;box-shadow:none!important}
.rangeslider .rangeslider__handle{background-color:var(--red)!important;border:3px solid var(--white)!important;box-shadow:none!important}
.rangeslider .rangeslider__handle-tooltip{width:auto!important;padding:0 15px}
.calculator-sec .btn-radio input[type=radio]+label,.calculator-sec .filtercalc-input label{min-height:auto}
.calculator-sec .input-sec select.form-control{width:150px}
.calculator-sec select.form-control{background:#fff url('../img/caret-down.svg') right .75rem center/10px 30px no-repeat}
.total-amount{background:url('../img/blog-back.png') no-repeat;max-width:1000px;margin:auto;padding:15px 150px 65px 150px}
.total-amount_sec{background-color:var(--white);padding:15px;display:flex;width:100%;align-items:center}
.total-amount_sec .small-text{font-size:16px;font-weight:600;max-width:50%}
.total-amount_sec .big-price{font-size:22px;font-weight:600}
.try-calculator-sec{background-color:var(--lhtblue)}
.try-calculator-sec .col-md-6{position:relative}
.try-calculator-sec .col-md-6:after{position:absolute;content:'';right:0;top:0;height:100%;width:1px;background-color:var(--red);opacity:.4}
.try-calculator-sec .col-md-6:last-child:after{display:none}
.try-calculator-col{box-shadow:0 0 15px 0 rgba(0,0,0,.1);background-color:var(--white);background-image:url('../img/cityscape.png');background-position:bottom center;background-repeat:no-repeat;background-size:contain;border-radius:16px;padding:3rem;margin-top:0;position:relative;z-index:2}
.try-calculator-col .calculator-column{max-width:315px;margin:auto}
.try-calculator-col .calculator-column .figure-img {max-height: 96px; width: auto;}
.try-calculator-col .title-sec{font-size:22px;color:var(--primary);font-weight:700;margin:1.5rem 0}
.try-calculator-col p{color:var(--dark)}
.try-calculator-col a:hover p{color:var(--primary)}
.home-banner{margin-top:-125px;}
.mob-banner{display: none;}
.home-banner .item{position:relative}
.home-banner .item figure img{width:100%}
.home-banner .home-banner_content{position:absolute;top:0;left:0;width:100%;height:100%;right:0;display:flex;align-items:center}
.home-banner .home-banner_content .content-sec{max-width:550px}
.home-banner .home-banner_content .content-sec .title{color:var(--primary);line-height:normal;font-weight:900}
.home-banner .home-banner_content .content-sec p{font-size:20px}
.home-banner .swiper-container{padding-bottom:0!important}
.home-banner .swiper-button-next,.swiper-button-prev{color:var(--red)!important}
.search-form{position:fixed;top:-117px;z-index:6;width:100%;padding:5px;background:var(--primary);transition-duration:.4s;border-radius:55px;max-width:350px}
.search-form.show{top:110px}
.search-form .form-group{margin:0;position:relative}
.search-form .form-group .form-control{padding-right:40px;border-radius:55px;border:none}
.search-form .form-group .btn-close,.search-form .form-group .btn-submit{position:absolute;top:-4px;right:0;border:none;background-color:transparent;min-width:inherit;z-index:7;padding:0;border-radius:0}
.search-form .form-group .btn-close:focus,.search-form .form-group .btn-submit:focus{box-shadow:none}
.search-form .form-group .btn-close{right:0}
.search-form .form-group .btn-submit{right:10px;display:none}
.search-form ul{list-style:none;padding:0}
.search-form ul li{display:flex}
.search-form ul li svg{margin-right:10px}
.request-callback{background-color:var(--primary);background-size:contain;background-repeat:no-repeat;background-position:bottom left;padding-left:60px;padding-right:60px}
.request-callback.career-form{padding:60px;margin-bottom:50px;border-radius:1rem;box-shadow:0 0 25px 0 rgb(0 0 0 / 10%);margin-top:50px}
.career-form .request-callback .entry-title .title-head,.career-form .request-callback .entry-title p{color:var(--primary)}
.request-callback .entry-title .title-head,.request-callback .entry-title p{color:var(--white)}
.request-form{color:rgba(255,255,255,.7)}
.career-form .request-form .form-control{background-color:transparent;border-radius:0;border:none;border-bottom:1px solid var(--white);color:var(--white);padding-left:40px}
.multiplecomp .request-form .form-control{background-color:transparent;border-radius:0;border:none;border-bottom:1px solid rgba(255,255,255,.7);color:var(--white);padding-left:40px}
.career-form .request-form .form-control:focus{box-shadow:none;border-bottom-color:var(--white)}
.career-form input.form-control-file{margin:7px 0 0 40px;position:relative;top:10px}
.career-form .request-form input::-webkit-input-placeholder{color:var(--white)}
.career-form .request-form input:-ms-input-placeholder{color:var(--white)}
.career-form .request-form input::-ms-input-placeholder{color:var(--white)}
.career-form .request-form input::-moz-placeholder{color:var(--white)}
.career-form .request-form input::placeholder{color:var(--white)}
.career-form .upload_aadhar_card_error.invalid-feedback{margin-top:13px}
.career-form label.form-label img{width:20px;object-fit:cover}
.multiplecomp .request-form .form-control:focus{box-shadow:none;border-bottom-color:var(--white)}
.multiplecomp .request-form input::-webkit-input-placeholder{color:var(--white);opacity:1}
.multiplecomp .request-form input:-ms-input-placeholder{color:var(--white);opacity:1}
.multiplecomp .request-form input::-ms-input-placeholder{color:var(--white);opacity:1}
.multiplecomp .request-form input::-moz-placeholder{color:var(--white);opacity:1}
.multiplecomp .request-form input::placeholder{color:var(--white);opacity:1}
.request-form .form-group{position:relative}
.request-form .form-label{position:absolute;margin:0;top:10px;left:10px;z-index:2;opacity:1}
.request-form select,.request-form select.form-control{background-image:url(../img/select-icon-wht.png);background-size:10px}
.form-control.is-invalid,.was-validated .form-control:invalid{background-size:10px}
.request-form select option,.request-form select.form-control option{color:var(--black);padding-left:0}
.modal-content{border-radius:0;border:none}
.callback-modal .modal-content{background:var(--primary)}
.callback-modal .request-callback{padding:3rem;background:var(--primary)}
.callback-modal .modal-header{background:0 0;border:none;padding:0}
.callback-modal .close{color:var(--white);opacity:1;font-weight:300;font-size:2.4rem; text-shadow:none;position:absolute; top:0; right:0; margin:0; padding:.8rem 1.5rem}
.chat-modal .modal-header{background:0 0;border:none;padding:0}
.chat-modal .close{color:var(--dark);opacity:1;font-weight:300;font-size:2.4rem; text-shadow:none;position:absolute; top:0; right:0; margin:0; padding:0 .5rem; z-index: 5;}
.chat-modal .modal-dialog { max-width: 350px; }

@media screen and (min-width: 1200px) and (max-width: 1400px) { 
.navbar-expand-xl .navbar-nav, .btn-secondary { font-size: 14px; }
}
@media only screen and (min-width:1200px){
.callback-modal .modal-dialog{max-width:1000px}
}
.breadcrumb{background-color:transparent;padding:0;margin:0}
.breadcrumb-item{font-weight:600;margin-bottom:0}
.breadcrumb-item a{color:#000}
.breadcrumb-item.active{color:var(--red)}
.inner-banner{background:url('../img/banner-background.jpg') top left;background-size:cover;height:100px;position:relative;padding-bottom:30%}
.banner-inside{position:absolute;top:0;left:0;width:100%;height:100%;display:flex;align-items:center;z-index:5}
.inner-banner .banner-inside.mode-white .banner-content .title,.inner-banner .banner-inside.mode-white .banner-content p,.inner-banner .banner-inside.mode-white .breadcrumb-item a,.inner-banner .banner-inside.mode-white .breadcrumb-item+.breadcrumb-item::before{color:var(--white)}
.inner-banner .banner-content .title{color:var(--primary);font-size:38px;font-weight:700;line-height:normal;margin:1.5rem 0 .8rem}
.inner-banner .banner-content .subtitle{color:var(--primary);font-weight:700;font-size:40px}
.inner-banner .banner-content p{font-size:20px}
.banner-img img{position:absolute;bottom:0;right:0;z-index:1;width:100%;height:100%;object-fit:cover}
.inner-banner .container{position:relative;z-index:2}
.loan-content{margin:2rem 0;padding:1rem;transition-duration:.4s}
.loan-content:hover{background-color:var(--lhtblue)}
.loan-content figure{overflow:hidden}
.loan-content figure img{transition-duration:.4s}
.loan-content figure:hover img{transform:scale(1.1)}
.loan-content .loan-content-row figure:hover img{transform:scale(1)}
.loan-content .title-sm{margin:2rem 0}
.loan-content .btn{margin:0}
.loan-content .ads figure:hover img{transform:scale(1)}
.loan-content-row{display:flex;align-items:center;margin:0 -15px;margin-top:20px}
.loan-content-box{padding:0 15px;width:100%}
.cta.apply_now_button{margin-top:10rem}
.quick-steps .title-md{color:var(--primary)}
.quick-steps .qsteps h3{text-transform:uppercase;margin:1.5rem 0;font-size:18px}
.sec-contact .container{position:relative;z-index:2;background:url(../img/blog-back.png) no-repeat 70px 0;padding-top:160px;background-size:160px;margin-top:-40px}
.contact-form{background-color:var(--white);border-radius:1rem;box-shadow:0 0 25px 0 rgba(0,0,0,.1);padding:1.8rem;transition-duration:.4s;position:relative}
.contact-form-left{border-radius:1rem 0 0 1rem;background:url(../img/blue-curve.jpg) #36297a no-repeat}
.contact-form p{font-size:20px}
.contact-form .form-control{background-color:transparent;border:none;border-bottom:1px solid rgba(0,0,0,.2);border-radius:0}
.contact-form .form-control:focus{border-bottom-color:var(--primary)}
.contact-column{background-color:var(--white);border-radius:1rem;box-shadow:0 0 25px 0 rgba(0,0,0,.1);padding:1.8rem;transition-duration:.4s;position:relative}
.contact-column:hover{background-color:var(--lhtblue)}
.contact-column a {color:var(--primary)}
.contact-column figure{width:45px}
.contact-column .title-xs{margin:0 0 .5rem 0}
.contact-column p{margin-bottom:.5rem}
.faq-search{max-width:650px}
.faq-search .form-group{position:relative}
.faq-search .form-label{position:absolute;top:15px;left:15px}
.faq-search .form-control{background-color:var(--white);border-radius:0;border:none;height:55px;padding-left:55px;box-shadow:0 0 10px 0 rgba(0,0,0,.1)}
.faq-search .form-control:focus{border-color:var(--primary)}
.Offerings-tabs.faq--tabs .nav{flex-wrap:nowrap;min-height:.1%;overflow-x:auto;overflow-y:hidden;justify-content:flex-start;padding:0}
.Offerings-tabs.faq--tabs .nav-tabs .nav-link{font-size:15px;white-space:nowrap}
.Offerings-tabs.faq--tabs .tab-content{padding:40px 70px 0;max-width:1600px}
.Offerings-tabs.investor--tabs .nav{flex-wrap:nowrap;min-height:.1%;overflow-x:auto;overflow-y:hidden;justify-content:flex-start}
.Offerings-tabs.investor--tabs .nav-tabs .nav-link{font-size:16px;text-align:center}
.Offerings-tabs.investor--tabs .tab-content{padding:60px 0}
.blu-back{background-attachment:fixed;background-color:var(--primary);background-image:url('../img/customer-bg.jpg');background-position:right bottom;background-repeat:no-repeat;color:var(--white);background-size:cover}
.blu-back .entry-title .title-head{color:var(--white)}
.lhtblu-back{background-image:url('../img/rightarc.png');background-position:top right;background-repeat:no-repeat;background-color:var(--lhtblue);position:relative}
.career-content{max-width:600px;margin:auto}
.career-at{position:relative;padding-bottom:100px}
.career-at:before{left:-50px;top:-50px}
.career-at:after{left:60%;top:auto;bottom:0}
.career-at .row{z-index:2;position:relative}
.Offerings-tabs.faq--tabs .nav::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 4px rgba(0,0,0,.3);box-shadow:inset 0 0 4px rgba(0,0,0,.3);border-radius:8px;background-color:#f5f5f5}
.Offerings-tabs.faq--tabs .nav::-webkit-scrollbar{height:12px;background-color:#f5f5f5}
.Offerings-tabs.faq--tabs .nav::-webkit-scrollbar-thumb{border-radius:8px;-webkit-box-shadow:inset 0 0 4px rgba(0,0,0,.3);box-shadow:inset 0 0 4px rgba(0,0,0,.3);background-color:rgba(0,0,0,.1)}
.career-behind-sec{background-color:var(--white);box-shadow:0 0 15px 0 rgba(0,0,0,.1);padding:30px;height:100%}
.career-behind-sec .title{font-size:22px;margin:1rem 0;font-weight:700}
.career-behind-sec p{margin:0}
.resume-sec{position:relative;bottom:-12px;z-index:5}
.resume-sec .container{max-width:1100px}
.resume-sec h4{color:var(--primary);font-size:40px}
.resume-sec a{color:var(--primary)}
.resume-sec a:hover{color:var(--red)}
.our-workplace{position:relative;background:url('../img/workplace-bg.jpg') no-repeat top center;background-size:cover;background-attachment:fixed;color:var(--white)}
.our-workplace:before{position:absolute;content:'';left:0;top:0;width:100%;height:100%;background-color:rgba(50,50,50,.8);z-index:1}
.our-workplace .container{position:relative;z-index:2}
.our-workplace .title-head{color:var(--white)}
.our-workplace .workplace-pnt ul{margin:0;padding:0;list-style:none}
.our-workplace .workplace-pnt ul li{float:left;width:33.33333%;padding:0 40px;margin-top:2rem;position:relative}
.our-workplace .workplace-pnt ul li:before{position:absolute;content:'';left:0;top:0;width:25px;height:25px;background:url('../img/tick.png') no-repeat;background-size:cover}
.opportunities-sec{position:relative;background:url('../img/opportunities-bg.jpg') no-repeat top center;background-size:cover;background-attachment:fixed;color:var(--white)}
.opportunities-sec .entry-title .title-head{color:var(--white)}
.value-sec{background:url('../img/blog-back.png') no-repeat left center;background-size:160px}
.value-sec .card{background:var(--lhtblue);border-radius:10px;border:none;box-shadow:0 0 8px 0 rgba(0,0,0,.05);font-size:.9rem}
.value-sec .card-body{padding:1.5rem}
.value-sec .card-body img{max-width:70px}
.vision-mission{position:relative}
.vision-mission .col-md-6 .column{border-right:1px solid rgba(0 0 0 / 10%)}
.vision-mission .col-md-6:last-child .column{border-right:none}
.vision-mission .column .figure-img{max-width:75px}
.vision-mission .column .figure-caption{margin:1rem 0;font-size:20px;font-weight:700}
.leadership-sec{background-image:url('../img/cityscape.png');background-position:top right;background-repeat:no-repeat;background-color:var(--lhtblue);position:relative}
.feature-column{display:flex;flex-wrap:wrap}
.feature-column .figure{min-width:25%;max-width:25%;border-right:1px solid rgba(0 0 0 / 10%);padding:25px;text-align:center}
.feature-column .figure:last-child{border-right:none}
.company-owner{position:relative}
.company-owner .message{max-width:500px;margin:auto;font-size:18px}
.company-owner .owner-name{color:var(--primary);font-size:24px;font-weight:700;margin-bottom:.8rem}
.company-owner .owner-des{color:var(--red);font-weight:600}
.company-owner .figure{position:relative}
.company-owner .figure:before{position:absolute;content:'';width:160px;height:160px;right:-80px;bottom:-80px;opacity:.5;background-size:100%;background-image:url('../img/blog-back.png');background-repeat:no-repeat}
.board-director{background:url('../img/rightarc.png') no-repeat right top var(--lhtblue)}
.leadership-team-sec{background-color:var(--lhtblue)}
.organization-building{position:relative;padding-top:120px}
.organization-building .container{position:relative;z-index:2}
.organization-building:after{left:auto;right:0;top:50%}
.members-section .member-column{padding:40px 25px;text-align:center}
.members-section .member-column .figure{margin-bottom:1.5rem}
.members-section .member-column .member-name{margin-top:1.5rem;font-weight:700;margin-bottom:.5rem;color:var(--primary)}
.team-slider .item{display:flex;align-items:center;transform:scale(.8);transition-duration:.4s}
.team-slider .item .figure{min-width:350px;max-width:350px}
.team-slider .item .team-member{background-color:var(--white);padding:25px 25px 85px 25px;box-shadow:0 0 10px 0 rgba(0 0 0 / 20%);position:relative;z-index:2;left:50px;display:none;min-height:300px}
.team-slider .item .team-member_name{color:var(--black);font-size:18px;font-weight:600;position:absolute;bottom:50px}
.team-slider .item .team-member_desig{color:var(--red);font-weight:600;position:absolute;bottom:20px}
.team-slider .swiper-slide-active .item{transform:scale(1)}
.team-slider .swiper-slide-active .item .team-member{display:block}
.investor-tabs{border-bottom:1px solid rgba(0 0 0 / 20%)}
.investor-tabs .container{position:relative;z-index:5}
.navbar-inner.navbar{justify-content:center}
.navbar-inner.navbar .navbar-nav{background:url('../img/blog-back.png') no-repeat 0 10px;margin-top:-55px;padding-top:120px}
.navbar-inner.navbar .nav-link{font-size:16px;text-align:center;padding:1rem;line-height:1.4;text-transform:uppercase;font-weight:600}
.navbar-inner.navbar .nav-link.active,.navbar-inner.navbar .nav-link:hover{color:var(--primary)}
.navbar-inner.navbar .nav-link:before{background-color:var(--primary)}
.navbar-inner.navbar .nav-link.active:before{width:100%}
.financial-body ul,.policy-listing .accordion .card-body ul{margin:0 0 30px;padding:0;list-style:none}
.financial-body ul li,.policy-listing .accordion .card-body ul li{margin:0;padding:0;background:url(../img/pdf-icon.png) center left no-repeat;position:relative}
.financial-body ul li a,.policy-listing .accordion .card-body ul li a{padding:15px 45px;display:block;color:var(--black)}
.financial-body ul li a:hover,.policy-listing .accordion .card-body ul li a:hover{color:var(--red)}
.career-telent{background-image:url(../img/cityscape.png),url(../img/rightarc.png);background-position:center bottom,right top;background-repeat:no-repeat,no-repeat;background-color:var(--lhtblue);background-size:1200px,auto;position:relative}
ul.twocol{display:flex;flex-wrap:wrap}
ul.twocol li{min-width:50%;max-width:50%}
.investor-column{border-top:1px solid #ccc;padding:2rem 0}
.investor-column:first-child{border-top:none}
.investor-column .title-xs{min-height:50px}
.investor-column.twocol .columns{min-height:inherit}
.investor-column.twocol .columns p{color:var(--black);font-size:20px}
.investor-column .big-txt{font-size:18px;font-weight:700;margin-bottom:.8rem}
.investor-column .columns{padding:40px;margin-bottom:2rem;background-color:var(--lhtblue);font-size:18px;color:var(--primary);font-weight:600;box-shadow:0 0 10px 0 rgba(0 0 0 / 10%);min-height:345px}
.investor-column .columns .big-txt{color:#ff1d28;font-size:20px}
.investor-column .columns .column-contact{display:flex;flex-wrap:wrap}
.investor-column .columns .column-contact .item{min-width:50%;max-width:50%;padding:.7rem 0;padding-left:50px;line-height:35px}
.investor-column .columns .column-contact .item.tel-ico{background:url('../img/tel-ico.png') no-repeat left center;background-size:35px}
.investor-column .columns .column-contact .item.fax-ico{background:url('../img/fax-ico.png') no-repeat left center;background-size:35px}
.investor-column .columns .column-contact .item.email-ico{background:url('../img/email-ico.png') no-repeat left center;background-size:35px}
.investor-column .columns .column-contact .item.web-ico{background:url('../img/web-ico.png') no-repeat left center;background-size:35px}
.investor-column .columns .column-contact .item a{color:var(--primary)}
.investor-column .columns .column-contact .item a:hover{color:#ff1d28}
.inner-banner.product-inner:before{position:absolute;content:'';z-index:1;width:100%;height:100%;left:0;top:0;background-color:rgba(255,255,255,.5)}
.inner-banner.product-inner .container{position:relative;z-index:2}
.inner-banner.product-inner .banner-content{text-align:center}
.inner-banner.product-inner .banner-content .breadcrumb{justify-content:left}
.loan-tabs{border-bottom:1px solid rgba(0 0 0 / 20%);position:sticky;top:0;z-index:3;background-color:transparent;margin-top:-58px}
.loan-tabs.affix{background-color:var(--white)}
.selectdrop{max-width:175px}
.selectdrop .form-control{border-radius:8px;border-color:var(--primary);height:45px;text-transform:uppercase}
.loan-listing_column .card{border:none;border-radius:16px;box-shadow:0 0 10px 0 rgba(0 0 0 / 20%);margin-bottom:3rem}
.loan-listing_column .card-header{padding:1rem 1.25rem;background-color:var(--primary);color:var(--white);border-bottom:none;text-transform:uppercase;border-top-left-radius:16px;border-top-right-radius:16px;font-size:18px}
.loan-listing_column .card-footer{padding:0 1.25rem 1rem 1.25rem;background-color:transparent;border-top:none;text-align:right}
.loan-listing .card .card-body .loanfields{padding:1.5rem 0;display:flex;flex-flow:wrap;border-top:1px solid rgba(0 0 0 / 20%);border-bottom:1px solid rgba(0 0 0 / 20%);margin:1rem 0;position:relative}
.loan-listing .card .card-body .loanfields .loanfields-column{min-width:33.33333%;max-width:33.33333%;display:flex;align-items:center}
.loan-listing .card .card-body .loanfields .loanfields-column strong{display:block;font-size:20px}
.loan-listing .card .card-body .loanfields .loanfields-column .figure-img{margin-right:.7rem}
.loan-detail-sec ul{margin:0 0 30px;padding:0;list-style:none}
.loan-detail-sec ul li{margin:0;padding:10px 35px;background:url(../img/tick.png) 0 10px no-repeat;background-size:22px;position:relative}
.loan-detail-sec ul li ul{margin:0;padding:0 0 0 25px;list-style:disc}
.loan-detail-sec ul li ul li{margin:0;padding:10px 0;background:0 0;position:relative}
.loan-detail-sec.got--questions .section.questions-sec{padding-top:0}
.loan-detail-sec.got--questions .entry-title{max-width:100%}
.loan-detail-sec.got--questions .entry-title.text-center{text-align:left!important}
.loan-detail-sec.got--questions .entry-title.text-center .title-head{display:none}
.max-loan span{display:inline-block;border:2px solid var(--primary);margin-bottom:25px;color:#7d8d9f;padding:0 25px 0 0;text-transform:uppercase;background:#e7e9ed;margin-right:25px}
.max-loan span strong{background:var(--primary);display:inline-block;color:var(--white);padding:10px 25px;margin-right:25px}
.table .thead-dark th{background-color:var(--primary);border-color:var(--primary)}
.cta .btn-transparent{color:var(--red)}
.cta .btn-transparent:hover{color:var(--primary)}
.tollfree-sec{position:fixed;top:50%;right:0;transform:translateY(-20px);z-index:6;max-width:45px}
.chat-sec{position:fixed;top:50%;right:-8px;transform:translateY(159px);z-index:6;max-width:45px; cursor: pointer;}
.chat-sec.desk-chat { display:block; }
.chat-sec.mob-chat { display:none; }
.payonline-sec{cursor:pointer;position:fixed;top:50%;right:0;transform:translateY(-195px);z-index:6;width:40px}
.applynow-sec{cursor:pointer;position:fixed;top:50%;right:0;transform:translateY(-105px);z-index:6;width:40px}
.partner-sec{cursor:pointer;position:fixed;top:50%;right:-1px;z-index:6;width:41px; transform: translateY(-17px);}
.partner-sec1{cursor:pointer;position:fixed;top:50%;right:-1px;z-index:6;width:41px; transform: translateY(71px);}
.card-body{padding:2.5rem 1.25rem}
.swiper-button-next,.swiper-button-prev{width:58px!important;height:58px!important}
.swiper-button-prev:after,.swiper-container-rtl .swiper-button-next:after{content:''!important;background:url('../img/prev-ico.png') no-repeat;background-size:cover;width:100%;height:100%}
.swiper-button-next:after,.swiper-container-rtl .swiper-button-prev:after{content:''!important;background:url('../img/next-ico.png') no-repeat;background-size:cover;width:100%;height:100%}
.board-director .swiper-button-next,.board-director .swiper-button-prev,.job-post-slider .swiper-button-next,.job-post-slider .swiper-button-prev,.leadership-team-sec .swiper-button-next,.leadership-team-sec .swiper-button-prev{top:auto;bottom:0}
.board-director .swiper-button-next,.board-director .swiper-container-rtl .swiper-button-prev,.job-post-slider .swiper-button-next,.job-post-slider .swiper-container-rtl .swiper-button-prev,.leadership-team-sec .swiper-button-next,.leadership-team-sec .swiper-container-rtl .swiper-button-prev{right:calc(50% - 60px)}
.board-director .swiper-button-prev,.board-director .swiper-container-rtl .swiper-button-next,.job-post-slider .swiper-button-prev,.job-post-slider .swiper-container-rtl .swiper-button-next,.leadership-team-sec .swiper-button-prev,.leadership-team-sec .swiper-container-rtl .swiper-button-next{left:calc(50% - 60px)}
.resume-sec-cont{text-align:center;padding-top:3rem;position:relative}
.resume-sec-cont a{color:var(--white)}
.transform-col{position:absolute;width:100%;text-align:center;color:#b8b6f6;font-size:7.2vw;line-height:normal;top:18px}
.job-post-slider .item{background-color:var(--lhtblue);border:1px solid var(--lhtblue);transition-duration:.4s}
.job-post-slider .item .post-sec{display:block;padding:25px;color:#757575}
.job-post-slider .item .post-sec .job-title{color:var(--primary);font-size:22px;font-weight:700;min-height:100px;transition-duration:.4s}
.job-post-slider .item .post-sec .job-description{border-top:1px solid rgba(0,0,0,.1);padding-top:5px;margin-top:.5rem;font-weight:600;transition-duration:.4s}
.job-post-slider .item .post-sec .job-description>div{margin-top:.5rem}
.job-post-slider .item:hover{background-color:var(--primary)}
.job-post-slider .item:hover .post-sec .job-description,.job-post-slider .item:hover .post-sec .job-title{color:var(--white)}
.job-post-slider .item:hover .post-sec .job-description{border-top-color:rgba(255,255,255,.2)}
.job-post-slider .swiper-button-prev:after,.job-post-slider .swiper-container-rtl .swiper-button-next:after{background:url('../img/prev-ico-wht.png') no-repeat;background-size:cover}
.job-post-slider .swiper-button-next:after,.job-post-slider .swiper-container-rtl .swiper-button-prev:after{background:url('../img/next-ico-wht.png') no-repeat;background-size:cover}
.cal-column .entry-title .title-head{font-size:28px;font-weight:700}
.calculator-sec .profile-radio .btn-radio input[type=radio]+label{padding:110px 0 0 0;display:block;width:200px;height:100px;margin:auto}
.calculator-sec .profile-radio .btn-radio input[type=radio]+label:before{top:0;width:100px;height:100px;margin:auto;left:0;right:0;border:1px solid #dcdcdc}
.calculator-sec .profile-radio .form-check-inline{align-items:flex-start;margin:0}
.calculator-sec .profile-radio .btn-radio .form-check-inline.man-sec input[type=radio]+label:before{background:url('../img/man-wht.png') no-repeat;background-size:cover}
.calculator-sec .profile-radio .btn-radio .form-check-inline.woman-sec input[type=radio]+label:before{background:url('../img/woman-wht.png') no-repeat;background-size:cover}
.calculator-sec .profile-radio .btn-radio .form-check-inline.senior-sec input[type=radio]+label:before{background:url('../img/senior-wht.png') no-repeat;background-size:cover}
.calculator-sec .profile-radio .form-check-inline input[type=radio]+label:after{width:100px;height:100px;border-color:transparent;left:0;right:0;margin:auto;top:0}
.calculator-sec .profile-radio .btn-radio .form-check-inline.man-sec input[type=radio]:checked+label:after{background:url('../img/man-col.png') no-repeat;background-size:cover}
.calculator-sec .profile-radio .btn-radio .form-check-inline.woman-sec input[type=radio]:checked+label:after{background:url('../img/woman-col.png') no-repeat;background-size:cover}
.calculator-sec .profile-radio .btn-radio .form-check-inline.senior-sec input[type=radio]:checked+label:after{background:url('../img/senior-col.png') no-repeat;background-size:cover}
.amount-bar label{margin:2.5rem 0}
.amount-bar .input-sec{border:1px solid #dcdcdc;max-width:325px;margin:2.5rem auto}
.amount-calculator{color:var(--white);padding:40px 25px;text-align:center;margin:2.5rem 0;background-image:linear-gradient(to bottom,#de222c,#ce1f28,#be1c24,#ae1921,#9f161d)}
.amount-calculator .col-md-4{border-right:1px solid var(--white)}
.amount-calculator .col-md-4:last-child{border-right:none}
.amount-calculator p{font-size:18px;margin:0}
.amount-calculator p strong{font-size:28px;margin:1rem 0 0 0;display:block}
.applycta{background:url('../img/blog-back.png') no-repeat calc(50% - 100px) 0;padding:40px 0}
.product-modal .modal-content{border-radius:20px;padding:1rem 2rem}
.product-modal .modal-header{padding-left:0;padding-right:0}
.product-modal .modal-header .close{font-size:2rem;font-weight:300;margin:0 0 0 auto;padding:0}
.product-modal .modal-title{font-size:1.2rem;font-weight:400;margin:0;padding-left:0}
.product-modal .modal-title strong{color:var(--primary)}
.product-modal .title{font-size:1rem}
.product-modal .btn-radio input[type=radio]+label::after,.product-modal .btn-radio input[type=radio]+label::before{display:none}
.product-modal .btn-radio input[type=radio]+label{border-radius:4px;border:solid 1px #ddd;padding:.5rem 1rem;font-size:.8rem;line-height:normal}
.product-modal .btn-radio input[type=radio]:checked+label{border-color:var(--primary);color:var(--primary)}
.map-animate{min-width:460px;max-width:460px;padding:0 10px;position:relative;margin:0 auto}
.map-animate svg{position:absolute;z-index:2}
.cls-1{stroke:#25eb88}
.cls-2{stroke:#ffb219}
.cls-3{stroke:#ea242e}
.cls-4{stroke:#af9eff}
.line{fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px;stroke-dasharray:400;stroke-dashoffset:400;animation:draw 4s infinite alternate}
.map-pointer{display:inline-block;width:9px;height:9px;border-radius:100%;background:var(--green);position:absolute}
.map-pointer:after{content:'';width:15px;height:15px;position:absolute;border-radius:100%;border:solid 1px var(--white);top:-3px;left:-3px}
.map-pointer:before{content:'';width:100%;height:100%;position:absolute;top:0;left:0;background:var(--white);border-radius:100%;-webkit-animation:ripple 1.6s infinite;animation:ripple 1.6s infinite;will-change:transform,opacity}
.animate-line{position:absolute;top:0;left:0}
.animate-line-1{top:71px;left:143px;width:41px;height:70px}
.animate-line-1 svg{top:3px;left:2px;width:41px}
.animate-line-1 .map--pointer-1{top:0;left:0;background:#25eb88}
.animate-line-1 .map--pointer-2{bottom:0;right:0;background:#19d0ff}
.animate-line-2{top:162px;left:34px;width:97px;height:88px}
.animate-line-2 svg{top:0;left:0;width:97px}
.animate-line-2 .map--pointer-1{top:1px;right:17px;background:#eb252f}
.animate-line-2 .map--pointer-2{bottom:-5px;left:20px;background:#ffb219}
.animate-line-3{top:235px;left:87px;width:130px;height:115px}
.animate-line-3 svg{top:0;left:0;width:130px}
.animate-line-3 .map--pointer-1{top:2px;right:20px;background:#fff}
.animate-line-3 .map--pointer-2{bottom:-5px;left:26px;background:#ea242e}
.animate-line-4{top:193px;left:228px;width:84px;height:52px}
.animate-line-4 svg{top:0;left:0;width:84px}
.animate-line-4 .map--pointer-1{top:-2px;left:-5px;background:#af9eff}
.animate-line-4 .map--pointer-2{bottom:-5px;right:-4px;background:#25eb88}
@-webkit-keyframes ripple{
0%{transform:scale(1);opacity:.6}
70%{transform:scale(4);opacity:0}
to{transform:scale(1);opacity:0}
}
@keyframes ripple{
0%{transform:scale(1);opacity:.6}
70%{transform:scale(4);opacity:0}
to{transform:scale(1);opacity:0}
}
@-webkit-keyframes draw{
from{stroke-dashoffset:400}
to{stroke-dashoffset:0}
}
@keyframes draw{
from{stroke-dashoffset:400}
to{stroke-dashoffset:0}
}
.active-filter{border:solid 1px rgba(58,45,125,.1)}
.filter-value{border:solid 1px var(--primary);border-radius:4px;padding:.4rem 1rem;font-size:.85rem;font-weight:600}
.feature-list h2,.feature-list h3,.feature-list h4,.feature-list h5,.feature-list h6{color:var(--primary);margin:0 0 1.5rem}
.feature-list ul{padding:0;list-style:none}
.feature-list ul li{padding:0 0 10px 40px;position:relative;background:url(../img/list.svg) no-repeat 0 0;background-size:24px}
.grid-table{overflow:hidden;border-radius:14px}
.table-primary thead th{background:var(--primary);color:var(--white);border-color:var(--primary);padding:1rem .7rem}
.table-primary tbody td{background:var(--lhtblue);border-color:#ccc;padding:1rem .7rem}
.cta-with-dots{padding:55px 0;position:relative}
.cta-with-dots:before{content:'';width:160px;height:160px;background:url('../img/blog-back.png') no-repeat center;background-size:100%;position:absolute;top:0;left:50%}
.cta-with-dots.left:before{left:50%;margin-left:-205px}
.card-desc{background:rgba(0,0,0,.04);border-radius:.5rem;width: 100%}
.card-desc p{margin:0}
.card-desc p+p{margin:15px 0 0}
.tab-wrapper .tab-content{border-top:solid 1px #ddd}
.tab-wrapper .nav-tabs{justify-content:flex-start;border:none}
.tab-wrapper .nav-tabs .nav-link{font-size:1rem;padding:1.5rem}
.card-post .card-subtitle{font-size:.85rem}
.card-post-img{position:relative;overflow:hidden;padding-bottom:60%;background-color:#f2f2f2}
.card-post-img .card-img{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;border-radius:0}
.financial-tabs-sec .financial-tab{position:relative;background-color:var(--primary);border-radius:20px;padding:60px 0}
.financial-tabs-sec .financial-tab:before{position:absolute;content:'';width:192px;height:150px;background:url('../img/blog-back.png') no-repeat bottom right;bottom:-100px;right:-20px}
.financial-tabs-sec .financial-tab .nav-link{background-color:transparent;color:var(--white);border-radius:0;border-left:6px solid var(--primary);font-weight:600;padding:.7rem 1rem;padding-left:50px;font-size:18px}
.financial-tabs-sec .financial-tab .nav-link.active{background-color:var(--white);color:var(--red);border-left-color:var(--red)}
.financial-tabs-sec .card-body{padding-bottom:0}
.financial-body{padding:2.5rem 1.25rem}
.loancal{display:flex;flex-wrap:wrap;justify-content:center}
.loancal .total-amount.centerflex{width:33.33333%;display:block;padding:20px;margin:3rem 0}
.loanprop .or-label{margin:0 2rem;font-size:1.2rem}
.customizedloan-offer .offer-header{background:#b9bbbe;margin-bottom:5px;color:var(--white)}
.customizedloan-offer h3{color:#dfdee2;font-weight:500;font-size:1.2rem;margin:0 0 7px 0}
.customizedloan-offer.is-active .offer-header{background:var(--primary)}
.customizedloan-offer.is-active .offer-header .edit{display:block;color:#5e707c;width:26px;height:26px;text-align:center;line-height:26px}
.customizedloan-offer.is-active .offer-header:before{background:#fff}
.customizedloan-offer.is-active .offer-header:after,.customizedloan-offer.is-active.completed .offer-header .small-text,.customizeloan-offer.is-active .mid-panel{display:block}
.customizedloan-offer.is-active.completed .offer-header .edit{color:#fff}
.customizedloan-offer.is-active.completed .offer-header:before{background-color:#41a70f;border-color:#41a70f}
.customizedloan-offer.is-active.completed .offer-header:after{content:'✓';width:auto;height:auto;background:0 0;color:#fff;left:21px;margin-top:-9px}
.customizedloan-offer.is-active.completed .mid-panel{display:none}
.offer-header{position:relative;padding:15px 50px}
.offer-header:after,.offer-header:before{position:absolute;content:'';left:15px;top:50%;margin-top:-11px}
.offer-header:before{width:21px;height:21px;border-radius:100%;border:1px solid #fff}
.offer-header:after{width:11px;height:10px;border-radius:100%;background:#ff9500;left:20px;top:50%;margin-top:-5px;display:none}
.offer-header .edit{position:absolute;top:50%;margin-top:-13px;right:15px;display:block}
.offer-header .small-text{font-size:13px;color:#ccc;text-overflow:ellipsis;white-space:nowrap;overflow:hidden}
.custom-testi .item .sec-pic{background-color:var(--white);padding:15px;border-radius:8px;box-shadow:0 0 15px 0 rgba(0,0,0,.1);height:23rem}
.custom-testi .item .sec-pic .vidico{position:relative;height:100%;width:100%}
.custom-testi .item .sec-pic .vidico:before{position:absolute;content:'';width:100%;height:100%;background:url('../img/video-ico.png') no-repeat center center;background-size:100px}
.custom-testi .item .sec-pic img{border-radius:8px;width:100%;height:100%}
.custom-testi .item .user-pic{width:80px;height:80px;border-radius:100%;margin:-60px 0 0 60px;position:relative;z-index:2}
.custom-testi .item .user-name{color:var(--black);font-weight:600;font-size:18px;margin:20px 0 0 60px;position:relative;z-index:2}
.custom-testi .item .user-name span{display:block;color:var(--red);font-size:14px}
.video-modal .modal-content{background:var(--black);border:none;border-radius:10px}
.video-modal .modal-dialog{margin:4rem auto 1rem}
.video-modal .modal-header{border:none;padding:0}
.video-modal .modal-body{position:relative;padding:0;border:4px solid var(--primary);border-radius:10px;overflow:hidden}
.video-modal .close{position:absolute;top:0;right:0;text-align:center;width:40px;height:40px;border-radius:100%;z-index:999;font-size:40px;font-weight:400;color:#fff;opacity:1;text-shadow:none;background:var(--primary);padding:0}
.video-modal .close:not(:disabled):not(.disabled):focus,.video-modal .close:not(:disabled):not(.disabled):hover{opacity:1;background:var(--primary);color:#fff}
.figure .twoimg{display:flex;justify-content:center;align-items:center}
.company-owner{overflow:hidden}
.map-animate.showmob{display:none}
.grid-table{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch}
.about-banner-wrp{position:relative;padding:66px 0;background-image:url('../img/contact-banner.jpg');background-position:center;background-repeat:no-repeat;background-size:cover;width:100%;height:100%;display:block}
.about-banner-wrp .banner-header h1{color:#fff;margin:0;text-transform:capitalize;font-size:2.8rem;line-height:1.4;font-weight:700}
.about-banner-wrp .banner-header small{color:#fff;font-size:1.375rem;font-weight:400;margin:0}
.thank-you-wrp{position:relative;padding:50px 0}
.thank-you-wrp .contact-form{position:relative;background:#fff;border:1px solid #e2e2e2;padding:5rem;margin-top:0;box-shadow:0 4px 6px 0 rgb(0 0 0 / 10%);text-align:center}
.thank-you-wrp .contact-form h4{font-size:2rem;margin:0;line-height:1.4;font-weight:700;color:var(--primary)}
.thank-you-wrp .contact-form small{font-size:1.2rem;font-weight:400;color:var(--primary)}
.enquiry-thank-you-wrp{position:relative;padding:50px 0}
.lanform .enquiry-thank-you-wrp .contact-form{background:var(--white-background);border:1px solid #e2e2e2;padding:5rem 0;margin-top:0;box-shadow:0 4px 6px 0 rgb(0 0 0 / 10%)}
.lanform .enquiry-thank-you-wrp .contact-form h2{text-transform:uppercase;font-size:3rem;line-height:.7;color:var(--white)}
.lanform .enquiry-thank-you-wrp .contact-form small{font-size:1.2rem;font-weight:400;color:var(--white)}
.amortization-schedule-sec .title-xs{color:var(--primary)}
.amortization-schedule{border-radius:20px;border:1px solid #ced1e1;overflow:hidden}
.custom-table.table{margin:0}
.custom-table.table th{color:var(--white);background-color:var(--primary);border-top:none}
.custom-table.table td,.custom-table.table th{padding:1rem;text-align:center}
.loan-amount-cal{display:flex;align-items:center;padding:30px;background-color:var(--lhtblue);border-radius:20px}
.loan-amount-cal .emi-inst{display:flex;align-items:center;justify-content:center;text-align:center;padding:20px;background-color:var(--primary);color:var(--white);border-radius:20px;font-size:24px;max-width:250px;min-width:250px;min-height:300px;margin-left:auto;position:relative}
.loan-amount-cal .emi-inst:before,.pre--payment:before{position:absolute;content:'';background:url('../img/blog-back.png');width:192px;height:150px;right:-55px;bottom:-90px}
.loan-amount-cal .emi-inst .price{font-size:30px;font-weight:600}
.calculator-pie .cal-detail,.calculator-pie .total-cal-detail{display:flex;align-items:center;border-bottom:1px solid #dedede;padding:1rem .5rem;font-weight:600}
.calculator-pie .cal-detail:last-child,.calculator-pie .total-cal-detail:last-child{border-bottom:none}
.calculator-pie .cal-detail .price,.calculator-pie .total-cal-detail .price{margin-left:auto}
.calculator-pie .total-cal-detail{font-weight:800}
.calculator-pie .total-cal-detail .price{color:var(--primary);font-size:1.3rem}
.calculator-pie .cal-detail .amount{display:flex;align-items:center}
.calculator-pie .dot{width:15px;height:15px;border-radius:100px;margin-right:10px;display:inline-block}
.tenor-type{min-width:100px;max-width:100px}
.loan-list{margin-right:1rem}
.loan-list label{font-size:20px;margin:0;font-weight:600;min-height:56px}
.loan-amount-cal.home--cal .loan-list label{min-height:inherit}
.piechart{width:100%;margin:auto}
.pre--payment .loan-list{max-width:100%;margin:0;width:100%}
.search-list-item{border-bottom:1px solid #ccc;padding-bottom:1.5rem;margin-bottom:1.5rem}
.search-list-item .title,.search-list-item .title a{font-size:1.5rem;color:var(--primary)}
.search-list-item .btn-link{color:var(--red);text-decoration:underline}
.search-list-item .btn-link:hover{color:var(--primary)}
.blog-entry-post{background-color:var(--white);position:relative;margin-bottom:5.5rem}
.blog-entry-post a,.blog-entry-post figure img{display:block;width:100%}
.blog-entry-post figure{position:relative;margin:0;max-height:226px;overflow:hidden;min-height:226px}
.blog-entry-post .post-cat,.blog-entry-post figure figcaption,.blog-entry-post figure img{transition-duration:.4s}
.blog-entry-post:hover figure img{opacity:.8}
.blog-entry-post figure figcaption{position:absolute;left:0;bottom:0;width:100%;padding:35px;color:var(--white);z-index:5;opacity:1;display:none}
.blog-entry-post:hover .post-cat,.blog-entry-post:hover figure figcaption{opacity:1;visibility:visible}
.blog-entry-post .post-cat{position:absolute;bottom:1.5rem;left:1.5rem;z-index:5;background:rgba(235,37,47,.6);color:var(--white);padding:0 15px;line-height:40px;transition-duration:.4s}
.blog-entry-post .post-date{padding:1.5rem 0 1rem;color:var(--gray)}
.blog-entry-post .post-title{padding:0;font-size:24px;line-height:normal;font-weight:600;color:var(--black)}
.blog-entry-post .post-link{text-decoration:underline}
.blog-banner figure{position:relative;margin:0}
.blog-banner figure figcaption{position:absolute;left:0;bottom:0;width:100%;padding:35px;color:var(--white);z-index:5;opacity:1}
.blog-banner .post-cat{position:absolute;bottom:1.5rem;left:1.5rem;z-index:5;background:rgba(235,37,47,.6);color:var(--white);padding:0 15px;line-height:40px;transition-duration:.4s}
#nav-below{margin:10px 0;padding:15px 0}
.nav-next a,.nav-previous a{display:block;color:#18113a;font-size:20px;position:relative;line-height:45px}
.nav-next a{padding-right:70px}
.nav-previous a{padding-left:70px}
.nav-next a:before,.nav-previous a:before{position:absolute;content:'';width:45px;height:45px;top:0}
.nav-previous a:before{background:url(../img/previous.jpg) no-repeat;background-size:cover;left:0}
.nav-next a:before{background:url(../img/next.jpg) no-repeat;background-size:cover;right:0}
.blog-tabs.Offerings-tabs.faq--tabs .nav{justify-content:center}
.blog-tabs.Offerings-tabs.faq--tabs .tab-content{justify-content:center;margin:0 auto;padding:40px 15px 0}
.swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled{display:none}

.rent-versus-breadcrumb{padding:40px 0 50px 0}
.rent-versus-sidebar{background:#f4fafa;border:1px solid #dfe4e5;border-radius:15px;padding:25px}
.calculator-sec-label{font-weight:600;font-size:16px;display:block}
.rent-versus-sidebar-title{color:#3a2d7d;border-bottom:2px solid #3a2d7d;display:inline-block}
.rent-versus-sidebar-box>div+div{margin-top:30px}
.rent-versus-sidebar-box+.rent-versus-sidebar-box{margin-top:40px}
.rent-versus-section .rangeslider-horizontal{height:5px}
.rent-versus-section .rangeslider-horizontal .rangeslider__handle{border:none!important;width:18px;height:18px}
.rent-versus-section .lrng,.rent-versus-section .rrng{color:#000;font-size:12px}
.cashflow-box{background:#f4fafa;border:1px solid #dfe4e5;border-radius:15px;padding:25px;height:100%}
.cashflow-box-list ul{margin:0;padding:0;list-style:none}
.cashflow-box-list ul li{display:flex;align-items:center;justify-content:space-between;margin:0}
.cashflow-box-list ul li p{margin:0;font-weight:600;color:#000;width:60%}
.cashflow-box-total ul{margin:0;padding:0;list-style:none}
.cashflow-box-total ul li{display:flex;align-items:center;justify-content:space-between;margin:0}
.cashflow-box-total ul li p{margin:0}
.cashflow-box-total p{color:#cd1f28;font-weight:700}
.cashflow-box-total span{color:#3a2d7d;font-weight:600}
.cashflow-box-total span svg{width:8px!important;height:17px;margin-right:5px}
.cashflow-box-total{border-top:2px solid #cd1f28;padding-top:15px;margin-top:15px}
.cashflow-box-list ul li+li{margin-top:10px}
.cashflow-box-list ul li span{width:40%;text-align:right;font-weight:600;color:#3a2d7d}
.cashflow-box-list ul li span svg{width:8px!important;height:17px;margin-right:5px}
.cashflow-box-list{margin-top:10px}
.total-benefit-row h5{margin:0;color:#fff;font-weight:600}
.total-benefit-row{display:flex;background:#3a2d7d;align-items:center;justify-content:space-between;padding:20px 20px;border-radius:15px;margin:20px 0 0 0}
.rent-versus-chart-section{border:1px solid #dfe4e5;border-radius:0;padding:15px;margin-top:15px}
.rent-versus-chart-section .sub-heading{text-align:center;ont-size:20px;color:var(--primary);margin:auto;min-height:90px;font-weight:600;padding:15px 0}
.rent-versus-chart-section .rent-versus-sidebar-title{margin-bottom:25px}
.capital-appreciation-table{border:1px solid #dfe4e5;border-radius:15px;overflow:hidden}
.capital-appreciation-table table{margin:0}
.capital-appreciation-table th{border:none!important;color:#3a2d7d!important}
.capital-appreciation-table td+td,.capital-appreciation-table th+th{border-left:1px solid #dfe4e5!important;text-align:center}
.capital-appreciation-table td:first-child{color:#3a2d7d}
.capital-appreciation-table td,.capital-appreciation-table th{font-size:14px;font-weight:600;color:#000;background:#f4fafa!important;padding:18px 15px}
.capital-appreciation-table td svg{width:6px!important;height:10px!important;position:relative;top:-1px}
.renting-sceneario-section{padding:100px 0 0 0}
.renting-sceneario-section .rent-versus-sidebar-title{margin-bottom:50px}
.chart-box-point{position:relative;text-align:right;width:auto}
.chart-box-point ul{padding:0;margin:0;list-style-type:none;display:flex;flex-wrap:wrap;justify-content:flex-end;width:auto}
.chart-box-point ul li{margin:0;display:flex;align-items:center;padding:10px;background:#f2f2f2}
.chart-box-point ul li span{margin:0;display:block;width:20px;height:20px}
.blue-point{background-color:#c2b6fe}
.red-point{background-color:#f5aeb2}
.chart-box-point ul li p{margin:0;padding-left:10px}
.chart-box-point-2{text-align:left;width:100%}
.chart-box-point-2 ul{justify-content:space-around;width:100%;background:#f2f2f2}
.graph-one-point-1{background-color:#ee424e}
.graph-one-point-2{background-color:#f5aeb2}
.graph-one-point-3{background-color:#d96268}
.graph-one-point-4{background-color:#c2b6fe}
.graph-one-point-5{background-color:#302375}
.pre-piechart{position:relative}
.piechart-txt{position:absolute;display:block;text-align:center;left:50%;top:50%;transform:translateX(-50%) translateY(-50%)}
.piechart-txt strong{font-size:1.3rem}
.piechart-txt .monthemi{display:block}
.office_address_map .inner{background-color:#fff;padding:25px;box-shadow:0 10px 30px 0 rgb(0 0 0 / 15%);border-radius:5px;position:relative;top:-160px}
p.office_location_info{margin:20px 0;color:#fff;text-align:center;font-size:15px}
.office_location{padding:50px 50px 30px 50px;height:325px}
.branch_locater{background-color:var(--primary)}
.branch_locater .office_location .office_list{display:flex;align-items:center;margin:0;justify-content:center}
.branch_locater .office_location .office_list .form-control{margin:0 7px;border:1px solid #dce1e5;color:var(--primary);font-weight:700;font-size:15px;width:240px;border-radius:3.5px}
.locations-box .name_kms{display:flex;justify-content:space-between;margin-bottom:15px;align-items:flex-end}
.locations-box h3.name{line-height:1;font-size:19px;font-weight:600;color:var(--primary);margin-bottom:0;position:relative;padding-left:30px}
.locations-box:hover h3{color:var(--red)}
.locations-box:hover .address p{color:var(--red)}
.locations-box svg.svg-inline--fa.fa-university{position:absolute;left:0;margin:0}
.address_list .locations-box{padding:15px;box-shadow:2px 1px 14px -2px #00000014;margin:15px 0}
.locations-box span.kms{color:#aaa;font-size:12px}
.locations-box .address,.locations-box .phone,.locations-box .time{font-size:15px;line-height:1.3;margin-bottom:10px;margin-left:35px;display:flex;color:var(--primary)}
.locations-box .address svg,.locations-box .phone svg,.locations-box .time svg,.locations-box h3.name svg{margin-right:15px;color:#eb252f}
.locations-box .address p,.locations-box .phone p,.locations-box .time p{margin-bottom:0}
.address_wrp .address_list{overflow-x:auto;height:500px;padding-right:15px;scroll-behavior:smooth}
.address_wrp .address_list::-webkit-scrollbar{width:4px;background-color:rgba(0,0,0,.4);border-left:1px solid #fff;border-right:1px solid #fff}
.branch_map_location>div{position:inherit!important;height:100%;min-height:400px}
p.marker-title{font-weight:600}
.marker-title-box{height:15px}
.address_wrp .address_list::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px;width:4px;border-left:1px solid #fff;border-right:1px solid #fff}
.address_wrp .address_list::-webkit-scrollbar-thumb{background:#d6d4d4;border-radius:10px;width:4px}
.existing_customer{padding:55px 65px}
.existing_customer_data ul li{list-style:none;border-bottom:1px solid #ccd6d9;color:#00334e;display:block;font-size:1.133em;line-height:1.47;margin:0 0 15px 0;padding:0 0 15px 0;position:relative;text-decoration:none}
.existing_customer_data ul li a{display:flex;justify-content:space-between;align-items:center;color:var(--primary)}
.existing_customer .title{text-align:center;margin-bottom:35px;color:var(--primary)}
.customer_home_loan .title{margin-bottom:35px;color:var(--primary)}
.existing_customer_data ul{padding:0}
.existing_customer_button{margin-bottom:30px}
.customer_home_loan{background-color:#f9f9f8;padding:55px 35px 31px 65px}
.customer_home_loan .home_loan_box>svg{font-size:43px;color:var(--red);margin-bottom:13px}
.customer_home_loan .box_text p{font-size:19px;color:var(--primary);margin-bottom:7px}
.customer_home_loan .home_loan_path{display:flex;flex-wrap:wrap}
.customer_home_loan .home_loan_box a{margin-top:10px;font-weight:700;font-size:18px}
.customer_home_loan .home_loan_box a svg{margin-left:20px}
.home_loan_path .home_loan_box{flex:0 0 50%;padding:15px 15px 15px 0}
section.trip_branch{margin-bottom:50px}
.existing_customer_button button{border-radius:100px;color:#fff;font-size:13px;font-weight:600;letter-spacing:3px;margin:7px 0 30px;padding:10px 39px;line-height:normal;text-decoration:none}
.home_loan_path button{border-radius:100px;color:#fff;font-size:13px;font-weight:600;letter-spacing:1px;margin:7px 0 30px;padding:10px 20px;line-height:normal;text-decoration:none}
.sign-in-page{background:#fff;padding:40px;width:450px;margin:0 auto;box-shadow:0 0 30px 0 rgb(0 0 0 / 30%);border-radius:8px}
.logo-signinpage{max-width:100px;display:block;margin-bottom:20px}
.signin-heading h2{font-weight:500;font-size:40px;color:#3c3c41;margin:0 0 30px}
.email-input{border:1px solid #b2b2b2;border-radius:6px!important;margin-bottom:15px}
.email-password{border:1px solid #b2b2b2;border-radius:6px!important;margin-bottom:15px}
.login-form .form-control{font-size:16px}
.form-control:focus{border-color:transparent}
.email-img{background:url(../img/input-img.png) no-repeat calc(100% - 15px) center;background-size:20px}
.password-img{background:url(../img/password-img.svg) no-repeat calc(100% - 15px) center;background-size:22px}
.button-arrow{background:url(../img/sign-in-button.png) no-repeat center;width:40px;height:100%;position:absolute;top:0;right:15px;-webkit-transition-duration:.4s;transition-duration:.4s}
.sign-in-button{width:100%;text-align:left;text-transform:uppercase;padding:2px 20px;position:relative;margin-top:17px;font-weight:400;font-size:20px;background:#5acddc!important;border-color:#5acddc!important}
.sign-in-button:hover{background-color:#39acbb!important;border-color:#39acbb!important}
.sign-in-button:hover .button-arrow{right:8px}
.username-img{background:url(../img/pen.svg) no-repeat calc(100% - 15px) center;background-size:20px}
.phone-img{background:url(../img/phone.svg) no-repeat calc(100% - 15px) center;background-size:20px}
.pmay-amount-cal .loan-list .common-label{display:block;text-align:right}
.pmay-amount-cal .loan-list .text-muted{display:block;font-weight:400;text-transform:none;font-size:14px}
.pmay-amount-cal .input-sec{border:1px solid #ccc}
.pmay-amount-cal .btn-radio input[type=radio]+label{padding:0 0 0 40px;margin:0}
.pmay-amount-cal .form-check-inline{margin-right:1.5rem;border:1px solid #ccc;padding:.5rem 2rem .5rem 1rem}
.pmay-amount-cal .subsidy-category{max-width:550px;margin:auto;color:var(--white);background-image:linear-gradient(to right bottom,#5b59aa,#504b9c,#453d8f,#3b3081,#302274)!important;border-color:var(--primary);padding:15px;font-size:1.3rem;font-weight:600;position:relative;margin-bottom:50px;border-radius:15px}
.pmay-amount-cal .subsidy-category-column{display:flex;align-items:center}
.pmay-amount-cal .subsidy-amount{max-width:550px;margin:auto;border-radius:15px;overflow:hidden;font-size:1.3rem;font-weight:600;color:var(--white);position:relative;margin-bottom:50px}
.pmay-amount-cal .subsidy-amount .column{position:absolute;left:0;bottom:0;padding:20px;text-align:center;width:100%}
.pmay-amount-cal .subsidy-amount .column .common-label,.pmay-amount-cal .subsidy-amount .column .common-price{min-width:100%;max-width:100%;text-align:center;position:relative;z-index:2}
.pmay-amount-cal .subsidy-amount .column .common-price{font-size:40px;margin:.5rem 0}
.pmay-amount-cal .subsidy-amount:before{position:absolute;content:'';width:192px;height:150px;top:10px;right:-75px;background:url('../img/blog-back.png') no-repeat;z-index:1;display:none}
.pmay-amount-cal .subsidy-category .column{font-size:14px;font-weight:400}
.pmay-amount-cal .subsidy-amount .common-label,.pmay-amount-cal .subsidy-category .common-label{min-width:50%;max-width:50%;text-align:left;position:relative;z-index:2}
.pmay-amount-cal .subsidy-amount .common-price,.pmay-amount-cal .subsidy-category .common-price{min-width:50%;max-width:50%;text-align:right;position:relative;z-index:2}
.faq-accordion{max-width:900px;margin:auto}
.tab-content>.tab-pane.active{height:auto}
.tab-content>.tab-pane{height:0;overflow:hidden}
.show_info{font-size:12px;padding:5px 0 0 0;margin:0}
.loan-list .show_info{display:block;/*min-height:45px*/}
.show_info_tenor{font-size:9.9px;margin-bottom: 0;}
.eligibility_not_applicable{color:red}
.error_page_detail{max-width:350px}
.error_page_detail span{display:block;font-size:16px;margin-bottom:15px}
.error_page_detail h6{font-size:28px;color:var(--primary)}
.error_page{margin:100px 0}
.purpose_apply{margin-top:20px;width:100%;text-align:center;display:flex;justify-content:center;margin-bottom:100px}
.purpose_apply_button{font-size:19px;padding:6px 12px!important;display:flex;align-items:center;color:#fff;background-image:linear-gradient(to right bottom,#eb252f,#bc1728,#8e0c20,#620516,#3a0205)!important;border-color:var(--red)!important;transition-duration:.4s!important;margin:0;font-weight:700;border-radius:8px;height:43px;justify-content:center}
.error_message{color:red; margin-bottom: 0;}
label.common-label { min-height: inherit; }
.rangeslider{margin:10px 0 15px 0!important}
.loan-amount-cal.home--cal{padding:0;background-color:transparent}
.loan-amount-cal.home--cal .input-sec{border:1px solid #ddd}
.loan-amount-cal.home--cal .emi-inst{background-color:var(--lhtblue);color:var(--dark)}
.loan-amount-cal.home--cal .emi-inst:before{display:none}
.convertNumberToWords{font-size:10px;line-height:normal;display:block;margin:7px 0 0 0}
.footer-link .footer-widget li{display:inline-block;margin:0 0 0 15px}
.footer-link .footer-widget li:first-child{margin:0}
.season_tabs{position:relative;min-height:339px;clear:both;margin:25px 0}
.season_tab:first-child label:before{content:"";position:absolute;width:256px;height:65px;background:#3a2d7d;top:-65px;left:-6px;border-radius:20px 20px 0 0}
.season_tab:last-child label:before{content:"";position:absolute;width:256px;height:65px;background:#3a2d7d;bottom:-65px;left:-6px;border-radius:0 0 20px 20px}
.season_tab label{background:#3a2d7d;padding:10px;margin-left:-1px;vertical-align:middle;position:relative;left:1px;display:inline-block;cursor:pointer;color:#fff;border-left:6px solid transparent;font-size:18px;padding-left:50px;font-weight:600;margin:0;width:256px}
.season_tab [type=radio]{display:none}
.season_content{position:relative;top:-80px;background:#fff;right:0;bottom:0;padding:20px;display:none;z-index:1;width:calc(100% - 257px);float:right}
.season_content span{animation:.5s ease-out 0s 1 slideInFromTop}
.body_tab{display:inline-block;width:100%}
[type=radio]:checked~label{background:#fff;color:#cd1f28;border-color:#cd1f28}
[type=radio]:checked~label~.season_content{z-index:1}
.season_tab [type=radio]:checked~.season_content{display:block}
summary{display:list-item;cursor:pointer;list-style:none}
.customtab a:after,summary::after{content:"";width:20px;height:20px;position:absolute;background-image:url('../img/plus.png');right:15px}
.summary{border-bottom:solid 1px #ddd;border-radius:0;padding:20px 45px 20px 25px;line-height:normal;text-transform:none;font-size:18px;text-align:left;width:100%}
.season_content .card-body{padding-bottom:0}
.season_content details{margin-bottom:24px}
.season_content details[open] summary{border:solid 1px #ddd}
.season_content details[open] summary::after{background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAADFBMVEUAAAAZEUAaEUEaEkLsYlEuAAAAA3RSTlMApJIuHE9bAAAAGElEQVQI12MgDzD9//+/gYERSDpASRIBADkHCfyZQ2aqAAAAAElFTkSuQmCC)}
.season_content details summary:focus{outline:0}
.customtab a,.season_content summary{border:solid 1px #ddd;border-radius:0;padding:20px 45px 20px 25px;line-height:normal;text-transform:none;font-size:20px;position:relative;display:block;text-align:left;width:100%;font-weight:600;border-top-color:transparent;border-right-color:transparent;border-left-color:transparent;color:var(--black)}
.card-body{padding:2.5rem 1.25rem}
.financial-body ul li a,.policy-listing .accordion .card-body ul li a{padding:15px 45px;display:block;color:var(--black)}
.season_content ul li a{padding:15px 45px;display:block;color:var(--black)}
.season_content ul{list-style:none;margin:0}
.season_content ul li{margin:0;padding:0;background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAgCAMAAADZqYNOAAAAn1BMVEUAAADa2tra2NvaRVTb2dy9QFC9QU/d3N69QVDg3ODb2NvaRVW/QFDs6uzhX3ja2NvnpLLbRlXo5ejfWG/g3eH4197wr7zohpnhZ37////otcDnrLjoh5ricYf+9ffr4eTq0NXqx8/jeI7iaYC9QVD64ebr2N3pvsjkipvkgZX0w83sm6vmmqjlkqPqkaLmfZHkc4nPT2TdTF7upbPdm6pu7x+FAAAADXRSTlMAMPnpv3/x4eDbrGAgatRSCAAAAPFJREFUKM990dluwjAQQNFQoHsmpsXxHmchkLAW2v//thoaVGNj3ydLRzMayYlp8pF6PY2SoeyOzh9GMc0MR9Swq7r6V8OO1ooPeu7FUUAotXK0UyKiHMqIlhCb3XZ0GVZailqHlPe6ojVCq3u6UkD7XQe7WnhqrL9cjCiUri6pUNeL9HXz+3T6p5WiPHVKnheLYTOvUk8fLxooGR+PP8hOaEtn4IZutSEEQJLm/Gg8zYt2DQwXB1ngL1+xLIBBvpYtAV8Z2xjd48AsADDSbqR5eLr/BgCMczjknp4+nbaWvo1nt524//t2js5jOskCvf4C/ik2okZSycIAAAAASUVORK5CYII=) center left no-repeat;position:relative}
.season_content .card-body{padding-bottom:0}
.body_tab .season_tab:nth-child(2) .season_content{margin-top:-45px}
.body_tab .season_tab:nth-child(3) .season_content{margin-top:-89px}
.body_tab .season_tab:nth-child(4) .season_content{margin-top:-135px}
.body_tab .season_tab:nth-child(5) .season_content{margin-top:-180px}
section.first_section{padding-top:95px}
section.second_section{padding-top:95px}
.second_section label .tab_section:after{content:"";position:absolute;width:256px;height:65px;background:#3a2d7d;bottom:111px;left:-6px}
section.second_section .season_tab:last-child label:before{content:"";position:absolute;width:256px;height:65px;background:#3a2d7d;bottom:0;left:-6px;border-radius:20px 20px 0 0}
section.second_section .season_tab:last-child label::after{content:"";position:absolute;width:256px;height:65px;background:#3a2d7d;bottom:-63px;left:-6px;border-radius:0 0 20px 20px}
section.third_section{padding-top:95px}
.third_section .third_section_title{font-size:18px;margin:0 0 1rem 0;line-height:normal;font-weight:700}
section.third_section .season_tab:last-child label:before{content:"";position:absolute;width:256px;height:65px;background:#3a2d7d;bottom:0;top:44px;border-radius:0 0 20px 20px}
.message-modal h1{color:#fff;font-size:28px;text-align:center;margin:0;padding:70px 50px}
.ico-sec img{max-width:150px}
.info-row{padding:50px 0}
.info-row .infotext{display:inline-block;width:32%;vertical-align:top;padding:30px 15px}
.info-row .infotext.last{width:50%}
.info-row .infotext img{display:block;margin:0 auto 20px}
.sec-about{color:#1b1440;position:relative;padding:30px 0}
.sec-about h3,.sec-about h4{margin:0 0 20px;font-size:19px}
.sec-about .col-left p{font-style:italic;font-size:19px;line-height:1.2}
.partners-row{position:relative;padding-top:30px;padding-left:15px}
.partners-row:before{content:'';background:#1b1440;height:200px;width:100%;position:absolute;top:0;right:0;border-radius:20px 0 0 20px}
.partners{background:#f8f8fc;border-radius:15px;padding:20px;font-size:18px;line-height:1.2;box-shadow:0 0 40px 0 rgb(0 0 0 / 20%);min-height:250px}
.partners img{display:block;margin:0 auto 20px}
.sec-about h4{margin:20px 0 0}
.sec-form .contact-form{margin-top:-80px!important;z-index:1}
.form-submit-btn button{width:200px;margin-top:15px}
.contact-form_sec .title-md{text-align:center;text-transform:uppercase;font-size:1.3rem;margin:0 0 30px;color:#1b1440}
.info-table{margin:80px 0;color:#333;font-size:18px;line-height:1.4}
.info-table .data-cell{border-left:solid 1px #d2d2d2;padding:30px 30px 0;vertical-align:top;position:relative}
.info-table .data-cell:first-child{border-left:none}
.info-table img{display:block;margin:0 auto 10px}
.info-table h3{color:#1b1440;line-height:1;font-size:20px;margin:0 0 20px}
.info-table h3 strong{display:block;font-size:70px}
.info-table .data-cell:before{content:'';width:6px;height:90px;position:absolute;top:0;left:0;background:#d2d2d2}
.sec-form .contact-form .form-label{display:none}
.tnc{margin:0;font-size:.7rem!important;color:#666}
.tnc a{color:#1b1440}
.notes{font-size:.89rem!important;color:#ea242c;margin:0}
.investor-content-wrapper{padding:50px 0}
.side-box{position:relative;background:#3a2d7d;border-radius:20px;padding:50px 0}
.side-box .btn-radio{display:block;width:100%;margin:0}
.side-box .btn-radio label{background:0 0;width:100%;padding:10px!important;border-left:0;color:#fff!important;text-align:center;font-weight:600!important;font-size:18px!important}
.side-box .btn-radio input[type=radio]:checked+label:before{border:0;width:100%;height:100%;border-radius:0}
.side-box .btn-radio input[type=radio]:checked label{color:#000!important}
.side-box .btn-radio input[type=radio]+label:after{width:5px;height:100%;position:absolute;top:0;left:0;background:red;border-radius:0}
.side-box .btn-radio input:checked+label,.side-box .btn-radio input[checked]+label{color:red!important}
.side-box [type=radio]:checked~label,.side-box [type=radio][checked]~label{background:#fff;color:#cd1f28;border-color:#cd1f28}
.side-box .btn-radio input[type=radio]+label:before{border:0}
.investor-content-wrapper .season_content{position:relative;top:0;background:#fff;right:0;bottom:0;padding:20px;display:block;z-index:1;width:100%;float:none}
.otp-loader{position:absolute;width:25px;top:50%;transform:translateY(-50%);right:0}
.people{position:relative}
.people .people-portrait{position:relative;margin:0;border:none;padding:0}
.people .people-portrait .people-portrait-content{position:absolute;top:0;left:0;z-index:2;background-color:rgba(58,45,125,.9);width:100%;height:100%;padding:30px;color:var(--white);transition-duration:.3s;opacity:0;visibility:hidden;display:flex;justify-content:center;align-items:center}
.people .people-portrait:hover .people-portrait-content{opacity:1;visibility:visible}
.people .people-portrait .people-portrait-content .people-name{font-size:24px;line-height:1.1;margin-bottom:1rem;font-style:normal}
.people .people-portrait .people-portrait-content .people-job{font-size:24px;line-height:1.1}
.people-content-wrapper{height:100%;left:0;opacity:0;position:absolute;-webkit-transition:opacity .6s ease-in-out;transition:opacity .6s ease-in-out;visibility:hidden}
.people-content-wrapper .people-close{position:absolute;bottom:25px;right:25px;width:20px;cursor:pointer;z-index:2}
.grid-item.is-active{left:0!important;width:100%;z-index:5}
.grid-item.is-active .people .people-portrait:hover .people-portrait-content{opacity:0;visibility:hidden}
.grid-item.is-active .people .people-content-wrapper{opacity:1;visibility:visible;margin-left:calc((100% - -1px)/ 3);width:calc((200% - 1.25rem)/ 3);color:var(--white);display:flex;align-items:center;background-color:var(--primary);padding:30px}
.grid-item.is-active .people .people-content-wrapper .content .title{font-size:24px;line-height:1.1}
.grid-item.is-active .people .people-content-wrapper .content .sub-title{font-size:20px;line-height:1.1}
.grid-item.is-active .people .people-content-wrapper .content .sub-title{font-size:20px;line-height:1.1} .content.short-cont {
    height: 250px;
    overflow: auto;
}
.grid-item{float:left;overflow:hidden;margin-bottom:20px}
.grid-item,.grid-sizer{width:350px;height:350px}
.gutter-sizer{width:20px}
.grid-sizer{position:absolute;top:0;left:0}
.ad-content{height:100%}
.ad-content a figure img{height:100%;min-height:278.71px}
.product-loan-sec .card{display:flex;flex-direction:row;align-items:center;gap:12px}
.product-loan-sec .card h5{margin:0}
.product-loan-sec .card p{margin:0;font-size:13px;padding-top:2.5px}
.sc-eCImPb>.wrapper{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;border:1px solid #dfe1e5;border-radius:24px;background-color:#fff;color:#212121;font-size:16px;z-index:0}
.sc-hKwDye{min-height:44px;width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.sc-bdvvtL.search-icon{-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;margin:0 0 0 16px;fill:grey}
.sc-hKwDye>input{width:100%;padding:0 0 0 13px;border:none;outline:0;background-color:rgba(0,0,0,0);font-size:inherit;color:#212121}
.sc-gsDKAQ,.sc-gsDKAQ a,.sc-gsDKAQ li,.sc-gsDKAQ li a{white-space:pre-wrap}
.blueback{background-color:var(--lhtblue)}
.advantage-sec .advantage-col{display:flex;flex-flow:wrap;justify-content:center}
.advantage-sec .advantage-col .item{text-align:center;padding:0 50px;margin:50px 0;border-right:1px solid #f3646b;flex:0 0 25%}
.advantage-sec .advantage-col .item:last-child{border-right:none}
.advantage-sec .advantage-col .item p{font-weight:700;color:var(--primary);font-size:18px}
.grid{display:flex;flex-flow:wrap;justify-content:center}
.lanform .sec-form .contact-form{margin-top:0!important}
.lanform .contact-form{background-color:transparent;border-radius:0;box-shadow:none;padding:0}
.lanform .notes,.lanform .tnc,.lanform .tnc a{color:var(--white)}
.lanform .tnc a{text-decoration:underline}
.board-committees-sec .housing-column{min-height:400px;padding:20px}
.board-committees-sec .housing-column p{margin-bottom:1rem}
.board-committees-sec .housing-column .title-sm,.board-committees-sec .housing-column strong{color:var(--primary)}
.customtab,.investab .card-header .btn{max-width:1000px;margin:auto}
.investab .navbar-inner.navbar .navbar-nav{padding-top:40px}
.career-behind{background-color:var(--lhtblue);background-image:url(../img/talent-bg.png);background-repeat:no-repeat;background-position:center bottom;background-size:cover}
.culture-col{background-color:var(--lhtblue);padding:40px 0;margin-bottom:40px;text-align:center;position:relative}
.culture-col .title{color:var(--primary);font-size:36px}
.culture-col .title strong{display:block;color:#5b59aa;font-size:28px}
.joblocation{font-weight:600;color:#757575;font-size:18px;margin-bottom:10px}
.joblocation .jobexp{position:relative;padding-left:25px;margin-left:5px}
.joblocation .jobexp:before{position:absolute;content:'';left:0;top:5px;width:12px;height:12px;border-radius:100%;background-color:#ff1d28}
.jobdate{opacity:.5;font-weight:600;color:#757575;margin-bottom:1rem;font-size:14px}
.backbtn{margin-bottom:1rem}
.backbtn a{color:#757575;background-image:url(../img/backarrow.svg);background-repeat:no-repeat;background-position:left center;display:inline-block;padding-left:30px;background-size:20px;opacity:.5}
.backbtn a:hover{opacity:1}
.jobmodal .modal-footer{justify-content:center}
.jobmodal .modal-title{color:var(--primary)}
.job-search-sec{margin-bottom:2rem}
.job-post-slider a{color:var(--white);opacity:.5}
.job-post-slider a:hover{color:var(--white);opacity:1}
.job-search-sec .search-column{display:flex;align-items:center}
.job-search-sec .search-column .form-control{background-color:var(--primary);border-color:#e1e1e1;border-radius:5px;color:var(--white);height:50px}
.apply-job .form-label{display:block;font-weight:700}
.apply-job .form-control{border-color:#e1e1e1}
.apply-job .d-flex select.form-control{flex:0 0 80px;max-width:80px}
.file-upload-form input[type=file]{display:none}
.file-upload-form ul{margin:0;padding:0;list-style:none}
.file-upload-form ul li{margin:7px 0}
#file-drag,.dropzone{border:2px dashed #302274;background-color:#e1e1e1;border-radius:7px;color:#555;cursor:pointer;display:block;padding:2em;text-align:center;transition:background .3s,color .3s;font-size:16px}
#file-drag strong,.dropzone strong{color:var(--dark)}
#file-drag img,#file-drag span,.dropzone img,.dropzone span{display:inline-block;vertical-align:middle;overflow:hidden;text-align:left}
#file-drag:hover,.dropzone:focus,.dropzone:hover{background:#e1e1e1}
#file-drag.hover,#file-drag:hover,.dropzone.hover,.dropzone:hover{border-color:#302274;border-style:solid}
#file-drag small,.dropzone small{display:block;font-size:14px;opacity:.5}
.job-post-slider.job-post-list .item{margin-bottom:30px}
img.custom-icon{position:fixed;right:0;bottom:53px;z-index:9999;max-width:120px;cursor:pointer}
svg.sc-bdvvtL.cmDhnZ.search-icon{display:none}
.sc-dkPtRN.jxsPXJ.clear-icon{display:none}
.referals-form.request-callback.multiplecomp{background-color:var(--white)}
.referals-form.request-callback.multiplecomp .entry-title .title-head{color:var(--primary)}
.referals-form.request-callback.multiplecomp .entry-title p{color:var(--dark)}
.referals-form.request-callback.multiplecomp .request-form .form-control{border-radius:8px;border:1px solid #757575;border-bottom:1px solid #757575;color:var(--dark);padding-left:.75rem}
.referals-form.request-callback.multiplecomp .request-form .form-label{font-weight:700;position:static;color:var(--dark);margin-bottom:1rem}
.referals-form.request-callback.multiplecomp .request-form{color:var(--dark)}
.referals-form.request-callback.multiplecomp .request-form select,.referals-form.request-callback.multiplecomp .request-form select.form-control{background-image:url(../img/select-icon.png)}
body.landingpage.micro-site{padding:0}
body.landingpage.micro-site .home-banner{margin-top:0}
.landingpage.micro-site #header,.landingpage.micro-site #header.is-fixed{position:static;height:auto}
.otpform{max-width:600px;margin:auto}
.pre--payment.lpan{padding-right:35px;border-right:1px solid #ccc}
.pre--payment.rpan{padding-left:15px}
.emi-sec{border:2px solid var(--primary);border-radius:15px;background-color:var(--white);overflow:hidden}
.emi-sec .header-sec{padding:20px}
.emi-sec .header-sec .column-col{min-height:125px}
.emi-sec .header-sec .video{width:100%;height:115px;margin-top:-25px}
.emi-sec .footer-sec small,.emi-sec .header-sec small{display:block;color:#1b1342;font-size:20px;white-space:nowrap}
.emi-sec .footer-sec p,.emi-sec .header-sec p{color:var(--primary);font-size:28px;font-weight:700;margin:0}
.emi-sec .footer-sec{background-color:var(--lhtblue);padding:18px}
.emi-sec .footer-sec p span{font-size:20px}
.emi-sec .footer-sec .sec-pic{flex:0 0 110px;max-width:110px;margin-right:1rem}
.totalcost-revised{padding:2rem}
.totalcost-revised .sec-pic{flex:0 0 200px;max-width:200px}
.totalcost-revised .contect-sec p{font-size:1.2rem;color:var(--primary);font-weight:500}
.totalcost-revised .contect-sec .bigtxt{font-size:2.5rem;font-weight:700}
.landingpage iframe.xdk-iframe{display:none}
iframe.xdk-iframe{width:125px!important;height:60px!important;bottom:65px!important;right:-8px!important;border:none!important}
#haptik-xdk-main-view{width:350px!important;height:500px!important;bottom:122px!important;right:0!important}
.landingpage #chatIcon{display:none!important}
.added-icici-banner .banner-sec.text-center,.careerClass div#chatIcon{display:none!important}
.auction-banner.inner-banner{background:0 0;height:auto;padding-bottom:0}
.auction-banner.inner-banner .banner-img img{position:static}
.auction-banner.inner-banner .banner-inside{align-items:end}
.auction-explore{background-color:var(--primary);padding:30px;margin-bottom:65px}
.multiplecomp .request-form.auction-explore .form-control,.multiplecomp.auction-explore .form-control{background-color:var(--primary);border-color:#e1e1e1;border-radius:0;color:var(--white);height:50px}
.multiplecomp .request-form.auction-explore select.form-control option,.multiplecomp.auction-explore .request-form select.form-control option{color:var(--white);padding-left:0}
.auction-explore.request-form{padding:40px}
.auction-explore .entry-title .title-head{color:var(--white)}
.auction-inner.inner-banner{background:0 0;padding-bottom:0}
.auction-inner.inner-banner .breadcrumb{margin:0}
.property-detail h1{font-size:24px;color:var(--primary)}
.property-detail h2{font-size:18px;color:var(--red);font-weight:600}
.property-detail p{font-size:16px;margin-bottom:1rem}
.property-detail p button{display:inline-block;border:none;background:0 0;font-weight:600;text-decoration:underline;color:var(--red);padding:0;margin:0}
.property-detail address{font-size:16px;font-weight:600;display:flex}
.property-detail address .sec-pic{flex:0 0 35px;max-width:35px;margin-right:1rem}
.property-detail .auction-col{display:flex;flex-wrap:wrap}
.property-detail .auction-col .column{display:flex;padding:10px;flex:0 0 33%;max-width:33%;margin:10px 0}
.property-detail .auction-col .column .sec-pic{flex:0 0 35px;max-width:35px;margin-right:1rem}
.property-detail .auction-col .column .column-cont strong{display:block;font-size:14px}
.property-detail .auction-col .column .column-cont span{display:block;font-size:14px}
.property-detail .auction-col .column .column-cont span.pro-price{font-size:18px}
.auctionmodal .modal-dialog{max-width:1024px}
.auctionmodal .modal-content{background-color:var(--primary);color:var(--white)}
.auctionmodal .modal-title{text-align:center;flex:0 0 100%;font-size:28px}
.auctionmodal .modal-title span{display:block;font-size:16px;font-weight:400;margin-top:10px}
.auctionmodal .modal-header{border-bottom:none;border-top-left-radius:0;border-top-right-radius:0}
.auctionmodal .modal-footer{justify-content:center;padding:1rem;border-top:none;border-bottom-right-radius:0;border-bottom-left-radius:0}
.auctionmodal .modal-header .close{position:absolute;right:20px;color:var(--white);opacity:1;z-index:5}
.property-img-sli{position:relative}
.property-img-sli .photos-count{position:absolute;right:15px;border-radius:8px;color:var(--white);z-index:5;top:15px;border:1px solid var(--white);min-width:100px;line-height:30px;text-align:center;text-transform:uppercase;font-size:13px;font-weight:700;background:rgba(0,0,0,.25)}
.property-slider .swiper-container{padding-bottom:0!important}
.property-slider .swiper-pagination-bullet{background:var(--white)!important}
.property-slider .swiper-pagination-bullet-active{background:var(--white)!important}
.property-slider .swiper-button-prev:after,.property-slider .swiper-container-rtl .swiper-button-next:after{background:url('../img/prev-ico-wht.png') no-repeat}
.property-slider .swiper-button-next:after,.property-slider .swiper-container-rtl .swiper-button-prev:after{background:url('../img/next-ico-wht.png') no-repeat}
.property-slider .swiper-container-horizontal>.swiper-pagination-bullets,.property-slider .swiper-pagination-custom,.property-slider .swiper-pagination-fraction{bottom:20px!important}
.property-slider .sec-pic .figure{position:relative}
.property-slider .sec-pic .figure .figure-caption{position:absolute;bottom:15px;right:15px;border-radius:8px;background-color:var(--white);z-index:5;text-align:center;min-width:110px;line-height:35px;font-weight:600;font-size:14px;padding:0 10px}
.property-img-sli .property-detail .auction-col .column{flex:0 0 50%;max-width:50%;padding-bottom:0}
.property-img-sli .property-detail .auction-col .column .column-cont span.strikout{text-decoration-line:line-through;opacity:.5}
.property-img-sli .property-detail .auction-col .column .column-cont span.pro-price{color:var(--red);font-weight:600}
.box-shadow{padding:15px;background-color:var(--white);box-shadow:0 0 10px 0 rgb(0 0 0 / 10%)}
.auction-filter{background-color:var(--lhtblue);box-shadow:0 0 10px 0 rgb(0 0 0 / 10%);max-width:280px}
.auction-filter .head{background:var(--primary);text-align:center;color:var(--white);padding:10px 15px;font-size:16px}
.property-img-sli.mb-5.box-shadow{max-width:400px;margin:auto}
.property-img-sli.mb-5.box-shadow .property-detail{min-height:255px}
.auctionmodal .modal-content{padding:20px 50px}
@-webkit-keyframes sideNavFoldedText{
0%{max-width:200px;opacity:.8}
100%{max-width:0;opacity:0}
}
@keyframes sideNavFoldedText{
0%{max-width:200px;opacity:.8}
100%{max-width:0;opacity:0}
}
.rs-slider{position:relative}
.rs-slider .rs-tooltip{display:none}
.rs-slider .rs-tooltip.rs-tooltip-placement-top .rs-tooltip::after{margin:auto;left:0;right:0}
.rs-slider-disabled{opacity:.3;cursor:not-allowed}
.rs-slider-disabled .rs-slider-bar,.rs-slider-disabled .rs-slider-handle::before{cursor:not-allowed}
.rs-slider-with-mark:not(.rs-slider-vertical){margin-bottom:29px}
.rs-slider-bar{height:6px;border-radius:3px;background-color:#f2f2f5;-webkit-transition:background-color ease-in-out .3s;transition:background-color ease-in-out .3s;cursor:pointer}
.rs-slider:hover .rs-slider-bar{background-color:#e5e5ea}
.rs-slider-vertical .rs-slider-bar{height:100%;width:6px}
.rs-slider-handle{position:absolute;top:-50%;outline:0}
.rs-slider-handle::before{content:'';position:absolute;width:12px;height:12px;border-radius:50%;border:2px solid #3498ff;background-color:#fff;margin-left:-6px;cursor:pointer;-webkit-transition:background-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;transition:background-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;transition:box-shadow .15s ease-in-out,background-color .15s ease-in-out,transform .15s ease-in-out;transition:box-shadow .15s ease-in-out,background-color .15s ease-in-out,transform .15s ease-in-out,-webkit-box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out}
.rs-slider-handle:focus::before,.rs-slider-handle:hover::before{-webkit-box-shadow:0 0 0 8px rgba(52,152,255,.25);box-shadow:0 0 0 8px rgba(52,152,255,.25)}
.rs-slider-handle.active::before,.rs-slider-handle:active::before{-webkit-transform:scale(1.2);transform:scale(1.2)}
.rs-slider-vertical .rs-slider-handle{top:unset}
.rs-slider-vertical .rs-slider-handle::before{left:3px;margin-top:-6px}
.rs-slider-handle.active .rs-tooltip,.rs-slider-handle:hover .rs-tooltip{display:block;opacity:1;top:-30px}
.rs-slider-vertical .rs-slider-handle.active .rs-tooltip,.rs-slider-vertical .rs-slider-handle:hover .rs-tooltip{top:-33px;margin-left:3px}
.rs-slider-mark{position:absolute;top:15px;left:-2px;white-space:nowrap}
.rs-slider-mark-content{margin-left:-50%}
.rs-slider-mark-last{left:auto;right:-2px}
.rs-slider-mark-last .rs-slider-mark-content{margin-left:50%}
.rs-slider-graduator{width:100%}
.rs-slider-graduator li,.rs-slider-graduator ol{list-style:none}
.rs-slider-graduator>ol{display:-webkit-box;display:-ms-flexbox;display:flex;padding-left:0;width:100%}
.rs-slider-graduator>ol>li{-webkit-box-flex:1;-ms-flex:1 1 1%;flex:1 1 1%;position:relative}
.rs-slider-graduator>ol>li::before,.rs-slider-graduator>ol>li:last-child::after{content:'';display:block;position:absolute;width:8px;height:8px;border-radius:50%;background-color:#fff;-webkit-box-sizing:border-box;box-sizing:border-box;border:2px solid #f2f2f5;margin-left:-4px;top:-1px}
.rs-slider-vertical .rs-slider-graduator>ol>li::before,.rs-slider-vertical .rs-slider-graduator>ol>li:last-child::after{top:unset;bottom:-4px;margin-left:-1px}
.rs-slider-graduator>ol>li:last-child::after{right:-4px}
.rs-slider-vertical .rs-slider-graduator>ol>li:last-child::after{left:0;bottom:unset;top:-4px}
.rs-slider-graduator>ol>li.rs-slider-pass::before{border-color:#3498ff}
.rs-slider-graduator>ol>li.rs-slider-active::before{visibility:hidden}
.rs-slider-vertical .rs-slider-graduator{display:block;height:100%}
.rs-slider-vertical .rs-slider-graduator>ol{width:6px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;height:100%;padding:0}
.rs-slider-vertical .rs-slider-graduator>ol>li{display:block;padding:0}
.rs-slider-progress-bar{position:absolute;height:6px;border-radius:3px 0 0 3px;background-color:#3498ff}
.rs-slider-vertical .rs-slider-progress-bar{width:6px;border-radius:0 0 3px 3px}
.rs-slider-vertical{height:100%}
.rs-slider-vertical .rs-slider-mark{top:unset;bottom:-8px;left:15px}
.rs-slider-vertical .rs-slider-mark-content{margin-left:auto}
.rs-slider-vertical .rs-slider-mark-last{bottom:unset;top:-8px}
.rs-slider-handle.active .rs-tooltip,.rs-slider-handle:hover .rs-tooltip{display:block;opacity:1;top:-30px}
.rs-slider-handle.active .rs-tooltip,.rs-slider-handle:hover .rs-tooltip{display:block;opacity:1;top:-30px}
.rs-tooltip{position:absolute;z-index:1070;display:block;font-size:12px;opacity:0;line-height:1.66666667;max-width:250px;padding:2px 10px;color:#fff;background-color:#272c36;border-radius:4px;overflow-wrap:break-word}
.rs-tooltip[class*=placement-top]{margin-top:-8px;-webkit-transform:translate(0,0);transform:translate(0,0)}
.rs-tooltip.placement-bottom::after,.rs-tooltip.placement-bottom::before,.rs-tooltip.placement-top::after,.rs-tooltip.placement-top::before{left:50%}
.rs-tooltip[class*=placement-top]::after{bottom:-6px;margin-left:-6px;border-width:6px 6px 0;border-top-color:#272c36}
.rs-tooltip-arrow::after,.rs-tooltip-arrow::before{content:' ';display:block;position:absolute;width:0;height:0;border-color:transparent;border-style:solid}
.dual-range.rs-slider{margin:10px 0;max-width:calc(100% - 15px)}
.rs-slider-bar{height:10px!important;border-radius:0!important;background-color:#e6e6e6!important;width:calc(100% + 15px)}
.rs-slider:hover .rs-slider-bar{background-color:#e6e6e6!important}
.rs-slider-progress-bar{height:10px!important;border-radius:0!important;background-color:var(--red)!important}
.rs-slider-handle::before{width:30px!important;height:30px!important;border:3px solid #fff!important;background-color:var(--red)!important}
.rs-slider-handle:focus::before,.rs-slider-handle:hover::before{box-shadow:none!important}
.rs-slider-handle.active::before,.rs-slider-handle:active::before{-webkit-transform:scale(1)!important;transform:scale(1)!important}
.rs-slider-handle{top:-10px!important}
.rs-input-group{border-radius:0!important;border:1px solid #fff!important}
.rs-input-number input[type=number]{-webkit-appearance:none!important;-moz-appearance:none!important;appearance:none!important;margin:0!important}
.rs-input-number-btn-group-vertical{display:none!important}
.rs-input-group-addon{padding:5px!important}
.rs-slider-handle:last-child::before{margin-left:-5px}
.rs-input{padding:5px!important;height:calc(2.25rem + 10px)}
.rangeslider-horizontal .rangeslider__handle{width:30px!important;height:30px!important}
.rangeslider-horizontal{height:10px!important}
.auctionmodal.imagemodal .modal-body,.auctionmodal.imagemodal .modal-content,.auctionmodal.imagemodal .modal-header{padding:0}
.auctionmodal.imagemodal .modal-body .sec-pic img{width:100%}
.auctionmodal.imagemodal .modal-body .sec-pic .sub-heading{position:absolute;top:15px;right:50px;background:var(--white);color:var(--primary);font-weight:700;padding:5px 15px;border-radius:15px}
.auctionmodal.imagemodal .modal-header .close{background:var(--white);border-radius:50px;color:var(--primary);padding:5px;top:30px;width:32px;height:32px;right:24px}
.auctionmodal.imagemodal .modal-content{border:5px solid var(--primary);border-radius:10px;overflow:hidden}
.auctionmodal.imagemodal .modal-content .property-slider .sec-pic .figure .figure-caption{bottom:inherit;top:13px;right:55px}
.home-loan-details,.other-details{min-height:530px}
.property-detail,.rent-details{min-height:340px}
.cashflow-row .btn.btn-dark,.rent-versus-sidebar-box .btn,btn-dark{background-color:var(--primary);padding:0;width:15px;height:15px;border-radius:100%;font-size:10px;line-height:15px;margin:0 0 5px 0}
.referral_form{max-width:1000px;margin:auto;background-color:var(--lhtblue);border:1px solid #ccc;padding:30px 30px 10px 30px;margin-top:-150px;position:relative}
.referral_form .request-form input::-webkit-input-placeholder{color:var(--black)}
.referral_form .request-form input:-ms-input-placeholder{color:var(--black)}
.referral_form .request-form input::-ms-input-placeholder{color:var(--black)}
.referral_form .request-form input::-moz-placeholder{color:var(--black)}
.referral_form .request-form input::placeholder{color:var(--black)}
.referral_form .entry-title .title-head{font-size:26px}
.referral_form1{max-width:1000px;margin:auto;background-color:var(--lhtblue);border:1px solid #ccc;padding:30px 30px 10px 30px;position:relative}
.referral_form1 .request-form input::-webkit-input-placeholder{color:var(--black)}
.referral_form1 .request-form input:-ms-input-placeholder{color:var(--black)}
.referral_form1 .request-form input::-ms-input-placeholder{color:var(--black)}
.referral_form1 .request-form input::-moz-placeholder{color:var(--black)}
.referral_form1 .request-form input::placeholder{color:var(--black)}
.referral_form1 .entry-title .title-head{font-size:26px}
.whatapp_barcode { padding: 1rem; text-align: center; }
.col-border { border-right: 1px solid #000; display: flex; align-items: center; justify-content: center; }
.whatapp_bar img { max-width: 100px; margin-top: 10px; }

.emi-form-col { max-width: 470px; }
.emi-form-col.form-col { max-width: 800px; margin: auto; }
.emi-form-col .form-label { display: block; margin: 0; }
.emi-form-col .form-control { border-bottom: 1px solid var(--dark); border-radius: 0; }
.emi-form-col.form-col .form-control { border: 1px solid var(--dark); }
.rdt.form-control.form-control { padding: 0; border: none; }
.emi-form-col .otp-form .form-control { max-width: 68px; }
.emi-form-col .otp-form .d-flex { gap:12px; }
.emi-formcol select, .emi-formcol select.form-control, .emi-formcol .form-control, .emi-formcol .form-control[disabled], .emi-formcol .form-control[readonly], .emi-formcol fieldset[disabled] .form-control { border: 1px solid #6d6d6d; background-color: var(--white); border-radius: 5px; }
.emi-formcol .form-control[disabled], .emi-formcol .form-control[readonly], .emi-formcol fieldset[disabled] .form-control {opacity:.3;}
.pay-emi-sec .btn-secondary { max-width: 470px; }
.signcol.text-center { font-size: 2rem; font-weight: bold; line-height: 85px; }
.emi-formcol .col1 { flex:0 0 52%; max-width: 52%; }
.emi-formcol .col2 { flex:0 0 30%; max-width: 30%; }
.emi-formcol .signcol { flex:0 0 5%; max-width: 5%; }
.emi-formcol .col2 .payamount { padding:0; line-height: 50px; font-size: 2rem; color: var(--primary); text-align: left; font-weight: bold; }

.otp-success { border-color:transparent; border-bottom: 1px solid var(--dark); background-color: transparent; padding-left: 0; padding-right: 0; color: #41A70F; border-radius: 0; font-weight: 500; }

.otp-failed { border-color:transparent; border-bottom: 1px solid var(--dark); background-color: transparent; padding-left: 0; padding-right: 0; color: #dc3545; border-radius: 0; font-weight: 500; }

.error_message_emi_pay{color:#dc3545; width: 100%;margin-top: 0.25rem;font-size: 80%;}

.emi-form-detail .form-detail-col { margin-bottom: 2rem; }
.emi-form-detail .form-detail-col label { display:block; font-weight: 700; color:var(--primary); }

.dark-table .table thead th { background-color: var(--drkblue); color: var(--white); white-space: nowrap; font-size: 14px; }
.dark-table .table td { white-space: nowrap; font-size: 14px; }

.referals-form.request-callback.multiplecomp .request-form .form-control .form-control { padding: 0; border: none; }

.dblock { display:block; }
.dnone { display:none; }
body.landingpage.footer-remove { padding-top:0; }
body.landingpage.footer-remove .footer, body.landingpage.footer-remove .header { display: none; }
.concent-banner { position: relative; background-color:var(--drkblue); background-repeat: no-repeat; background-image:url(../img/landing-banner-pic.png); background-size: cover; display: flex; padding: 25px; background-position: top left; padding-bottom: 125px; overflow: hidden; }
.concent-banner .top-logo { max-width: 525px; flex:0 0 525px; }
.concent-banner .concent-content { margin-left: auto; max-width: 60%; flex:0 0 60%; background-color:rgba(255,255,255,.9); padding: 40px; border-radius: 13px; }
.concent-banner .concent-footer { position: absolute; bottom: 20px; width: 100%; padding: 0 15px; }
.concent-banner .concent-footer p { font-size: 14px; color: var(--white); }
.concent-banner .concent-footer p { font-size: 14px; }
.concent-banner a { color:var(--drkblue); }
.concent-banner .form-control { border-color: var(--drkblue); }
.concent-banner .form-control[disabled] { opacity: .5; }

.showmore { border:none; background-color: transparent; border-bottom: 1px solid var(--white); color:var(--white); padding: 0; }
.showmore svg { margin-left: 5px; width: 15px; height: 15px; }
.showmore .expanded svg { margin-left: 5px; width: 15px; height: 15px; }

.seo-content { background-color: var(--lhtblue); }
.seo-content h1, .seo-content h2, .seo-content h3, .seo-content h4, .seo-content h5, .seo-content h6 { color: var(--primary); }
.seo-content h2 { font-size: 22px; }
.seo-content h3 { font-size: 18px; }
.seo-content h4 { font-size: 16px; }

.table td ul, .table th ul { margin:0; }

body.hhfl-video-page #header .container, body.hhfl-video-page #header.is-fixed .container { display: none; }
body.hhfl-video-page #header.is-fixed { background-color: transparent; box-shadow: none; }
body.hhfl-video-page #header .nav-secondary .container, body.hhfl-video-page #header.is-fixed .nav-secondary .container { display: block; }
body.hhfl-video-page { padding-top: 0; }
body.hhfl-video-page .breadcrumb-item.active { color: var(--white); }


@media only screen and (max-width:3000px){
.container{max-width:1470px}
}
@media only screen and (max-width:1399px){
.satisfied-pnt .points p strong{font-size:34px}
.right-navbar .navbar-nav .nav-link{border-bottom:none}
.satisfied-pnt .points:first-child figure{max-width:40px}
.satisfied-pnt .points figure{max-width:45px}
.satisfied-pnt .points:last-child figure{max-width:50px}
.concent-banner { background-position: -100px 0; }
.hhfl-video-page .banner-inside { padding: 7% 0 0 0; }
}
@media only screen and (max-width:1299px){
.total-amount_sec,.total-amount_sec span{display:block;text-align:center}
.total-amount_sec .small-text{max-width:100%}
}
@media only screen and (min-width:1200px){
.container{max-width:85%}
#footer .container,#header .container{max-width:100%}
}
@media only screen and (min-width:1399px){
.container{max-width:1270px}
#footer .container,#header .container{max-width:1470px}
.grid-item.is-active .people .people-content-wrapper{margin-left:calc((100% - 130px)/ 3)}
.homeloan-form.request-callback .sec-form{max-width:500px}
.transform-col{font-size:5.8vw;top:8px}
}
@media only screen and (max-width:1199px){
.info-table img{max-width:70px}
.info-table h3 strong{font-size:40px}
.info-table .data-cell{padding:10px 10px 0}
.info-table,.info-table h3{font-size:16px}
.container{max-width:100%}
.right-navbar{display:flex;padding-right:35px}
.right-navbar .navbar-nav{width:100%;flex-direction:row}
.right-navbar .navbar-nav .contact-ico{display:none}
.right-navbar .navbar-nav .nav-link{padding:0}
.navbar-brand{margin-right:0}
.navbar{min-height:45px}
.navbar-light .navbar-toggler-icon{background-image:none;position:relative}
.navbar-toggler{position:absolute;top:17px;right:0;padding:0;width:35px;height:40px;z-index:60;margin:0;background-color:transparent;background-image:none;border:none;border-radius:0}
.navbar-toggler .navbar-toggler-icon{display:block;width:100%;height:2px;border-radius:0;background-color:transparent;position:relative}
.navbar-toggler .navbar-toggler-icon:after,.navbar-toggler .navbar-toggler-icon:before{position:absolute;content:'';width:100%;height:2px;left:0;background-color:var(--primary)}
.navbar-toggler .navbar-toggler-icon:before{transform:rotate(45deg);transition-duration:.4s}
.navbar-toggler .navbar-toggler-icon:after{transform:rotate(-45deg);transition-duration:.4s}
.navbar-toggler.collapsed .navbar-toggler-icon{background-color:var(--primary)}
.navbar-toggler.collapsed .navbar-toggler-icon:before{top:-10px;transform:rotate(0)}
.navbar-toggler.collapsed .navbar-toggler-icon:after{top:10px;transform:rotate(0)}
.navbar-collapse{position:fixed;top:0;right:-300px;transition-duration:.1s;background:var(--white);overflow:auto;width:300px;padding:45px 5px 25px 10px;text-align:left;z-index:55;height:100vh}
.navbar-collapse.show{right:0;box-shadow:0 0 10px 0 rgba(0,0,0,.1)}
.navbar-nav .nav-link{padding:.8rem 0}
.nav-indicator{display:none}
.dropdown-menu{border:none}
.navbar-nav .nav-link{font-size:var(--font14)}
.navbar-nav .dropdown-menu .row>[class*=col-]:nth-child(2n){background-color:transparent}
.navbar-nav .dropdown-menu .dropdown-menu-item{padding:0}
.navbar-light .right-navbar .navbar-nav .language-ico .dropdown-menu{position:absolute;right:0;left:inherit;min-width:150px}
.navbar-light .right-navbar .navbar-nav .language-ico .dropdown-menu .dropdown-item{padding:.5rem 1rem}
.navbar-light .navbar-nav .nav-item{display:block}
.navbar-light .navbar-nav .nav-link.contact-ico,.navbar-light .navbar-nav .nav-link.whatsapp-ico,.navbar-light .navbar-nav .search-ico{width:25px;height:25px;background-size:cover}
.navbar-brand{width:125px}
.search-form .btn{max-width:50px}
.calculator-column .figure{max-width:70px;margin:auto}
.housing-column .figure{max-width:70px}
.try-calculator-col{padding:40px 20px}
.try-calculator-col .calculator-column{margin:1rem auto!important}
.career-behind-sec .title,.try-calculator-col .title-sec{font-size:18px}
.accordion .card-header .btn,.btn-lg,.calculator-sec label,.home-banner .home-banner_content .content-sec p,.question-tab .nav-item .nav-link,.total-amount_sec .small-text{font-size:16px}
.title-lg,h1{font-size:36px}
.title-md,h2{font-size:30px}
.title-sm,h3{font-size:24px}
.title-xs,h4{font-size:20px}
.title-xxs,h5{font-size:16px}
.total-amount_sec .big-price{font-size:26px}
.entry-title .title-head,.inner-banner .banner-content .title{font-size:36px}
.inner-banner .banner-content .title{margin:1.5rem 0 1.5rem}
.card-body{padding:1rem}
.housing-column{padding:20px}
.housing-column .title-xxs{min-height:40px;margin:1rem 0}
.satisfied-pnt .points{margin-bottom:1.5rem}
.satisfied-pnt .points .figure{max-width:45px}
.career-behind-sec .figure{max-width:75px}
.satisfied-pnt .points p strong{font-size:32px}
.satisfied-pnt .points p span.mt-5{margin-top:.5rem!important}
.request-callback{background-size:150px;padding-left:140px;padding-right:40px}
.inner-banner{min-height:375px}
.inner-banner .banner-content .title{margin:1rem 0}
.career-behind-sec{padding:25px;min-height:305px}
.our-workplace .workplace-pnt ul li{min-height:100px}
.contact-form{padding:30px}
.loan-listing .card .card-body .loanfields{align-items:flex-start}
.table img{max-width:25px}
.home-banner .home-banner_content .content-sec{max-width:500px;padding-left:30px}
.btn-lg{padding:0 1.5rem;line-height:48px}
.content-sec .cta.mt-5{margin-top:2rem!important}
#footer .copyright-sec{margin-top:2rem}
#header{padding:0;background-color:var(--white);position:fixed;box-shadow:0 0 10px 0 rgba(0 0 0 / 10%);height:auto}
.swiper-button-next,.swiper-button-prev{width:45px!important;height:45px!important}
.swiper-button-prev,.swiper-container-rtl .swiper-button-next{left:0}
.swiper-button-next,.swiper-container-rtl .swiper-button-prev{right:0}
.custom-testi .item .user-pic{width:90px;height:90px;margin:-45px 0 0 45px}
.custom-testi .item .user-name{margin:0 0 0 45px}
.question-tab .nav-item .nav-link{padding:15px}
.navbar-inner.navbar .nav-link{font-size:16px}
body{padding-top:110px}
body.landingpage{padding-top:55px}
body.hhfl-video-page.landingpage{padding-top:29px}
.home-banner{margin-top:-50px}
.loanprop .or-label{margin:0 1rem;font-size:1rem}
.blog-tabs.Offerings-tabs.faq--tabs .nav{justify-content:flex-start}
.blog-related-list.blog-list .entry-post .post-cat{top:70%}
.emi-calculator{overflow:hidden}
.search-form.show{right:0}
.address_list .locations-box{padding:0;box-shadow:none;margin:15px 0}
.grid-item,.grid-sizer,.people .people-portrait img{width:480px;height:480px}
.homeloan-form.request-callback{position:static;width:100%}
.homeloan-form.request-callback .sec-form{max-width:100%;box-shadow:none}
.inner-banner .banner-content .subtitle{font-size:24px}
.inner-banner.auction-banner .banner-inside{position:relative;margin-top:-400px}
.auction-inner.inner-banner{min-height:100px}
}
@media only screen and (max-width:991px){
body{font-size:14px;line-height:1.4}
.section{padding:30px 0}
.btn{font-size:14px;padding:0 1rem;line-height:40px;min-width:125px}
.housing-column .title-xxs{min-height:inherit}
.housing-column{margin:1rem 0!important}
.satisfied-customer .map-fig{min-width:inherit;max-width:inherit}
.customer-content .item .user-in.fo{padding:55px 15px 15px 15px}
.customer-content .item{padding:0}
.entry-title{margin-bottom:2rem}
.blog-sec .cta.mt-5{margin-top:1rem!important;margin-bottom:2rem}
.copyright-sec{text-align:center}
#footer .social-links ul{justify-content:center;margin-top:1rem}
#footer .footer-widget{max-width:100%;margin:0 0 2rem 0}
#footer .footer-widget figure{max-width:175px;margin:auto}
.inner-banner{min-height:345px}
.request-callback{padding:30px}
.request-callback{background-size:60px}
.Offerings-tabs .nav-tabs .nav-link,.company-owner .owner-name,.inner-banner .banner-content p,.try-calculator-col .title-sec{font-size:16px}
.accordion .card-header .btn,.btn-lg,.calculator-sec label,.company-owner .message,.home-banner .home-banner_content .content-sec p,.question-tab .nav-item .nav-link,.total-amount_sec .small-text{font-size:14px}
.title-lg,h1{font-size:30px}
.title-md,h2{font-size:24px}
.title-sm,h3{font-size:20px}
.title-xs,h4{font-size:18px}
.title-xxs,h5{font-size:14px}
.entry-title .title-head,.inner-banner .banner-content .title{font-size:26px}
.qsteps .figure{max-width:55px}
.members-section .member-column{padding:30px 20px}
.career-behind-sec{padding:20px;min-height:275px}
.our-workplace .workplace-pnt ul li{width:50%;padding:0 35px;margin-top:1rem;min-height:60px}
.tollfree-sec{max-width:40px}
.loan-tabs{margin-top:20px}
.navbar-inner.navbar-light .navbar-nav{flex-wrap:nowrap;min-height:.1%;overflow-x:auto;overflow-y:hidden;justify-content:flex-start}
.navbar-inner.navbar-light .navbar-nav .nav-link{font-size:16px;white-space:nowrap}
.navbar-inner.navbar-light .navbar-nav::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 4px rgba(0,0,0,.3);box-shadow:inset 0 0 4px rgba(0,0,0,.3);border-radius:8px;background-color:#f5f5f5}
.navbar-inner.navbar-light .navbar-nav::-webkit-scrollbar{height:12px;background-color:#f5f5f5}
.navbar-inner.navbar-light .navbar-nav::-webkit-scrollbar-thumb{border-radius:8px;-webkit-box-shadow:inset 0 0 4px rgba(0,0,0,.3);box-shadow:inset 0 0 4px rgba(0,0,0,.3);background-color:rgba(0,0,0,.1)}
.table td,.table th{padding:10px 5px}
.table img{max-width:20px}
.try-calculator-sec .col-md-6:nth-child(4n+2):after{display:none}
.home-banner .home-banner_content .content-sec{max-width:370px}
.home-banner .item figure img{max-width:115%;width:115%}
ul.twocol{display:block}
ul.twocol li{min-width:100%;max-width:100%}
.satisfied-customer .entry-title{max-width:inherit}
.contact-form .p-5{padding:15px!important}
.financial-tabs-sec .financial-tab .nav-link{padding-left:20px;font-size:16px}
.financial-tabs-sec .financial-tab{padding:20px 0}
.feature-column .figure{min-width:50%;max-width:50%}
.feature-column .figure:nth-child(2n){border-right:none}
.satisfied-pnt .points{max-width:33.33333%;min-width:33.33333%}
.satisfied-pnt{width:inherit}
.housing-section{min-height:inherit}
.grid-item,.grid-sizer,.people .people-portrait img{width:350px;height:350px}
.grid-item.is-active .people .people-content-wrapper .content .sub-title{font-size:18px}
.grid-item.is-active .people .people-content-wrapper .content p{font-size:13px}
.grid-item.is-active .people .people-content-wrapper .content .title,.people .people-portrait .people-portrait-content .people-job,.people .people-portrait .people-portrait-content .people-name{font-size:24px}
.loan-content>div{min-height:425px}
.right-navbar .language-ico{line-height:37px}
.advantage-sec .advantage-col .item{flex:0 0 250px;padding:20px;margin:0;border-right:none}
.pre--payment.lpan,.pre--payment.rpan{padding-left:0;padding-right:0;border-right:none}
.inner-banner.auction-banner .banner-inside{margin-top:-300px;transform:none}
.product-inner .banner-inside{transform:none}
.inner-banner.auction-banner .banner-inside .breadrow,.product-inner .banner-inside .breadrow{transform:scale(.6) translate(-30%,-30%)}
.inner-banner.auction-banner .banner-inside .banner-content{max-width:100%}
.auction-explore,.auction-explore.request-form{padding:20px}
.inner-banner.product-inner{padding-bottom:30%}
.chat-sec.desk-chat { display:none; }
.chat-sec.mob-chat { display:block; }
}
@media only screen and (max-width:767px){
.home-banner{display: none;}
.mob-banner{display: block;}
.info-table{margin:40px 0 0}
.info-row{padding:30px 0 0}
.info-row .infotext,.info-row .infotext.last{width:auto;max-width:300px;margin:0 auto;display:block;padding:15px 0}
.info-row .infotext img{max-width:150px;margin:0 auto 10px}
.info-table .data-cell:before,.info-table .data-cell:first-child:before{height:60px;display:block}
.info-table .data-cell{padding:0 0 0 10px;display:block;margin-bottom:20px}
.partners-row{padding:0}
.partners-row:before{content:none}
.partners{min-height:inherit;margin-bottom:20px}
.partners p{font-weight:700;font-size:16px}
.info-table h3,.info-table p{padding:0 0 0 80px;margin:0 0 10px}
.info-table .data-cell:first-child{border-left:solid 1px #d2d2d2}
.info-table img{margin:0;float:left}
.calculator-sec label{text-align:center;margin:0;min-height:inherit}
.calculator-sec .rangesli{margin-bottom:1rem}
.total-amount{padding:25px}
.customer-content .item .user-info{max-width:80%;margin-left:auto;margin-right:auto}
.question-tab{flex-wrap:nowrap;min-height:.1%;overflow-x:auto;overflow-y:hidden;justify-content:flex-start;flex-direction:row!important;margin-bottom:1.5rem}
.question-tab .nav-item .nav-link{border:none;border-bottom:3px solid;border-bottom-color:transparent;border-radius:0;padding:15px}
.question-tab .nav-item .nav-link.active{border-bottom-color:var(--red)}
.question-tab .nav-link{font-size:16px;white-space:nowrap}
.question-tab::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 4px rgba(0,0,0,.3);box-shadow:inset 0 0 4px rgba(0,0,0,.3);border-radius:8px;background-color:#f5f5f5}
.question-tab::-webkit-scrollbar{height:12px;background-color:#f5f5f5}
.question-tab::-webkit-scrollbar-thumb{border-radius:8px;-webkit-box-shadow:inset 0 0 4px rgba(0,0,0,.3);box-shadow:inset 0 0 4px rgba(0,0,0,.3);background-color:rgba(0,0,0,.1)}
.Offerings-tabs .nav-tabs .nav-link{padding:15px}
.vision-mission{max-width:100%;margin-bottom:2.5rem;text-align:center}
.company-owner .figure,.telect-sec .figure{margin-bottom:2rem}
.career-content,.contact-column{max-width:100%;margin:2rem 0}
.resume-sec{bottom:0;text-align:center}
#footer .footer-widget{margin:0}
#footer .footer-title{border-bottom:1px solid rgba(255,255,255,.1);cursor:pointer;display:block;margin:0;padding:15px 0;position:relative}
#footer .footer-title:after,#footer .footer-title:before{-webkit-transition:.5s all ease-out;transition:.5s all ease-out;display:inline-block;content:'';width:14px;height:2px;background:var(--white);position:absolute;top:50%;right:20px;opacity:1}
#footer .footer-title:before{opacity:1;-webkit-transform:rotate(90deg);transform:rotate(90deg)}
#footer .footer-title:after{opacity:1;-webkit-transform:rotate(0);transform:rotate(0)}
#footer .footer-title.active:before{-webkit-transform:rotate(0);transform:rotate(0);opacity:1}
#footer .footer-title.active:after{-webkit-transform:rotate(-90deg);transform:rotate(-90deg);opacity:0}
.loan-detail-sec ul li{padding:10px 0 10px 35px}
.entry-title{max-width:90%}
.home-banner .item figure img{max-width:100%;width:100%}
.banner-content{max-width:350px}
.banner-inside.justify-content-center .banner-content{max-width:95%;margin-left:auto;margin-right:auto}
.inner-banner{min-height:auto}
.contact-form{padding:10px}
.contact-form-left{border-radius:1rem}
.Offerings-tabs .nav-tabs .nav-link,.company-owner .owner-name,.inner-banner .banner-content p,.try-calculator-col .title-sec{font-size:14px}
.mid-panel{padding:15px 0}
.panel-links aside{padding:0}
.panel-links{border-left:none}
.loancal .total-amount.centerflex{width:50%;padding:15px;margin:1rem 0}
.loanprop .or-label{display:none}
.loanprop .btn{display:inline-block;margin:10px}
.nav-next a{padding-right:40px}
.nav-previous a{padding-left:40px}
.nav-next a,.nav-previous a{font-size:12px;line-height:30px}
.nav-next a:before,.nav-previous a:before{width:30px;height:30px}
.blog-related-list.blog-list .entry-post .post-cat{top:70%}
.blog-related-list.blog-list .entry-post{margin-bottom:2rem}
.pmay-amount-cal .loan-list .common-label{text-align:left}
.banner-img img{position:static}
.banner-inside{transform:scale(.6) translate(-30%,-30%);height:auto}
.inner-banner{padding-bottom:0;height:auto}
.banner-inside{padding:10px 0}
.customer_home_loan{padding:20px}
.existing_customer{padding:0}
.office_address_map .inner{padding:20px;top:0}
.office_location{padding:20px;height:auto}
.address_wrp{padding-bottom:25px}
section.trip_branch{margin-top:50px}
.grid-item,.grid-sizer,.people .people-portrait img{width:600px;height:600px}
.grid-item.is-active .people .people-content-wrapper{margin-left:0;width:100%;padding:45px 15px 15px 15px;display:block}
.people-content-wrapper .people-close{bottom:inherit;top:15px;right:inherit;left:15px}
.home-banner .home-banner_content{left:-90px;transform:scale(.7)}
.home-banner .swiper-container-horizontal>.swiper-pagination-bullets,.home-banner .swiper-pagination-custom,.home-banner .swiper-pagination-fraction{bottom:10px!important}
.home-banner{margin-top:-20px}
.try-calculator-col{margin-top:0}
.loan-content>div{min-height:inherit}
.grid-item.is-active .people .people-content-wrapper .content .title,.people .people-portrait .people-portrait-content .people-job,.people .people-portrait .people-portrait-content .people-name{font-size:20px}
.job-search-sec .search-column{flex-flow:wrap}
.search-column .mb-0.form-group,.search-column .w-100.mr-3.mb-0.form-group{width:46%!important;margin-bottom:1rem!important}
#haptik-xdk-main-view{width:100%!important;height:100%!important}
.sec-form.auction-explore.multiplecomp.mt-5{margin-top:0!important}
.inner-banner.auction-banner .banner-inside{margin-top:-135px}
.auction-inner.inner-banner{min-height:45px}
.property-detail{padding-top:25px}
.property-slider .sec-pic .figure .figure-caption{min-width:100px;line-height:30px;font-size:12px}
.emi-form-col { max-width: 100%; }
.landingpage .navbar-brand { display:block; width:100%; max-width: 125px; }
.landingpage #header .nav-primary { padding: 5px 0; position: relative; }
.landingpage #header .nav-primary:before { position: absolute; content: ''; width:100%; height: 100%; top:0; left:0; z-index: 2; }
.landingpage .navbar-brand img { max-width: 125px; }
.mblock { display:block; }
.mnone { display:none; }
.concent-banner { flex-direction: column; padding-bottom: 25px; background-size: 200%; background-position: 0 -100px; }
.concent-banner .top-logo, .concent-banner .concent-content { max-width: 100%; flex: 0 0 100%; }
.concent-banner .top-logo { margin-bottom:250px; }
.concent-banner .concent-content { margin-bottom:25px; }
.concent-banner .concent-footer { position:static; text-align: left!important; }
.hhfl-video-page .banner-inside { padding: 20% 0 0 5%; }

}
@media only screen and (max-width:639px){
.try-calculator-sec .col-md-6:after{display:none}
.loan-listing .card .card-body .loanfields .loanfields-column strong,.loan-listing_column .card-header{font-size:16px}
.members-section .member-column{min-width:100%;max-width:100%;padding:20px}
.team-slider .item{display:block}
.team-slider .item .team-member{padding:25px 25px 85px 25px;left:0;min-height:inherit}
.team-slider .item .figure{min-width:inherit;max-width:inherit}
.career-behind-sec{min-height:inherit}
.our-workplace .workplace-pnt ul li{width:100%;min-height:inherit}
.resume-sec h4{font-size:30px}
.map-animate.showmob{display:block}
.map-animate.showdesk{display:none}
.map-animate{min-width:100%;max-width:100%}
.contact-form form.p-5{padding-left:0!important;padding-right:0!important}
.callback-modal .request-callback{padding:3rem 1rem}
.satisfied-pnt .points{max-width:50%;min-width:50%}
.loan-amount-cal{display:block}
.loan-list{margin-right:0}
.loan-amount-cal .emi-inst{min-width:100%;max-width:100%}
.satisfied-pnt{justify-content:center}
.home_loan_path .home_loan_box{flex:0 0 100%;padding:15px}
.grid-item,.grid-sizer,.people .people-portrait img{width:550px;height:550px}
.home-banner .home-banner_content{left:-90px;transform:scale(.5)}
.right-navbar .language-ico{padding:0 10px;min-width:inherit}
.navbar-light .navbar-nav .nav-link.btn{font-size:14px;padding:0 10px}
.nav-link{padding:.5rem 10px}
.inner-banner .banner-content .subtitle{font-size:20px}
.emi-sec .footer-sec small,.emi-sec .header-sec small{font-size:14px}
.emi-sec .footer-sec p,.emi-sec .header-sec p{font-size:18px}
.emi-sec .footer-sec .sec-pic{flex:0 0 65px;max-width:65px}
.emi-sec .header-sec .video{width:100%;height:100%;margin-top:-15px;max-width:110px}
.emi-sec .header-sec .column-col{min-height:75px}
.totalcost-revised{padding:0}
.totalcost-revised .d-md-flex{display:flex}
.totalcost-revised .sec-pic{flex:0 0 100px;max-width:100px}
.totalcost-revised .contect-sec .bigtxt{font-size:2rem;margin-bottom:0}
.totalcost-revised .contect-sec p{font-size:1rem}
.property-detail .auction-col .column{flex:0 0 50%;max-width:50%}
.inner-banner.product-inner{padding-bottom:42%}
.padding_checkbox {padding-top: 15px; }
}
@media only screen and (max-width:540px){
.inner-banner.product-inner{padding-bottom:58%}
}
@media only screen and (max-width:479px){
.loan-listing .card .card-body .loanfields .loanfields-column{min-width:50%;max-width:50%;margin-bottom:1.5rem}
.satisfied-pnt .points{min-width:100%;max-width:100%;margin-bottom:1.5rem;border-right:none;text-align:left}
.navbar-brand{width:110px}
.navbar-toggler{top:11px;width:30px}
.navbar-toggler.collapsed .navbar-toggler-icon:before{top:-8px}
.navbar-toggler.collapsed .navbar-toggler-icon:after{top:8px}
.total-amount_sec,.total-amount_sec .big-price,.total-amount_sec .small-text{display:block;text-align:center}
.calculator-sec .centerflex{display:block}
.feature-column .figure{min-width:100%;max-width:100%;border-right:none;border-bottom:1px solid rgba(0,0,0,.1)}
.custom-testi .item .sec-pic .vidico:before{background-size:75px}
.satisfied-pnt .points p{width:100%}
.satisfied-pnt .points p strong{border-right:none}
.satisfied-pnt .points figure.satisfied-pnt .points figure:last-child,.satisfied-pnt .points figure:first-child{margin:0 15px 0 0}
.piechart{width:200px;margin:auto}
.nav-link{padding:.5rem}
nav.d-flex.nav-secondary.bg-primary .d-flex.justify-content-end.nav{justify-content:center!important}
.piechart-txt{position:static;transform:none}
#header .nav-secondary .nav-link{font-size:.6rem;padding:.5rem .25rem}
.grid-item,.grid-sizer,.people .people-portrait img{width:340px;height:340px}
.grid-item.is-active .people .people-content-wrapper .content .title,.people .people-portrait .people-portrait-content .people-job,.people .people-portrait .people-portrait-content .people-name{font-size:16px;margin-bottom:5px}
.grid-item.is-active .people .people-content-wrapper .content .sub-title{font-size:14px;margin-bottom:5px}
.grid-item.is-active .people .people-content-wrapper .content p{font-size:12px;margin-bottom:5px}
.people-content-wrapper .people-close{top:10px;left:15px}
.grid-item.is-active .people .people-content-wrapper{padding:40px 15px 15px 15px}
.language-ico.mr-3.btn.btn-outline.nav-item.dropdown,.navbar-light .navbar-nav .search-ico,a.user-ico.btn.btn-secondary.ml-0.nav-link{margin-right:5px!important}
.right-navbar .language-ico{line-height:27px;font-size:11px}
.navbar-light .navbar-nav .nav-link.btn{font-size:11px;padding:0 5px;min-width:inherit;line-height:30px}
.advantage-sec .advantage-col .item{flex:0 0 200px}
.search-column .mb-0.form-group,.search-column .w-100.mr-3.mb-0.form-group{width:100%!important;margin-right:0!important;margin-bottom:1rem!important}
.inner-banner.product-inner{padding-bottom:65%}
.btn{font-size:12px;padding:0 10px;line-height:31px;min-width:inherit}
.user-ico.btn.btn-secondary.mr-2{max-width:90px;line-height:normal;padding:5px}
.concent-banner { background-position: 0 -50px; }
.concent-banner .top-logo { margin-bottom:200px; }
}
@media only screen and (max-width:380px){
.navbar-brand{width:75px}
.property-detail .auction-col .column{flex:0 0 90%;max-width:90%}
.inner-banner.product-inner{padding-bottom:85%}
.react-datepicker-wrapper { display: inline-block; padding: 0; border: 0; width: 100% !important }
.react-datepicker__input-container input { height: 35px; }
.concent-banner { background-position: 0 -20px; }
.concent-banner .top-logo { margin-bottom:150px; }
}
